const TRANSLATABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "headline",
    "subHeadline",
    "description",
    "visualHeroButtonText",
    "ruleNotice"
];
Object.freeze(TRANSLATABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST);
const EXTENDABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST = [
    "name",
    "description",
    "serviceTemplateIdentifiers",
    "ruleGroups",
    "rules",
    "ruleNotice",
    "isVisual",
    "visualType",
    "visualContentType",
    "isVisualDarkMode",
    "visualBlur",
    "visualHeroButtonText",
    "shouldForceToShowVisual"
];
Object.freeze(EXTENDABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST);
var EContentBlockerVisualType;
(function(EContentBlockerVisualType) {
    EContentBlockerVisualType["Default"] = "default";
    EContentBlockerVisualType["Wrapped"] = "wrapped";
    EContentBlockerVisualType["Hero"] = "hero";
})(EContentBlockerVisualType || (EContentBlockerVisualType = {}));
var EContentBlockerVisualContentType;
(function(EContentBlockerVisualContentType) {
    EContentBlockerVisualContentType["Map"] = "map";
    EContentBlockerVisualContentType["VideoPlayer"] = "video-player";
    EContentBlockerVisualContentType["AudioPlayer"] = "audio-player";
    EContentBlockerVisualContentType["FeedText"] = "feed-text";
    EContentBlockerVisualContentType["FeedVideo"] = "feed-video";
    EContentBlockerVisualContentType["Generic"] = "generic";
})(EContentBlockerVisualContentType || (EContentBlockerVisualContentType = {}));
var EContentBlockerTestStatus;
(function(EContentBlockerTestStatus) {
    EContentBlockerTestStatus["Initial"] = "initial";
    EContentBlockerTestStatus["Running"] = "running";
    EContentBlockerTestStatus["Passed"] = "passed";
    EContentBlockerTestStatus["Failed"] = "failed";
})(EContentBlockerTestStatus || (EContentBlockerTestStatus = {}));
const EXPORT_REMOVABLE_CONTENT_BLOCKER = [
    "id",
    "logo",
    "logoId",
    "release",
    "releaseId",
    "extends",
    "next",
    "nextId",
    "pre",
    "preId",
    "extendsId",
    "translationIds",
    "extendedTemplateId",
    "translationInfo",
    "translatableRequiredFields",
    "translatedRequiredFields",
    "translatableOptionalFields",
    "translatedOptionalFields",
    "translationFlaggedFields",
    "version"
];
Object.freeze(EXPORT_REMOVABLE_CONTENT_BLOCKER);
export { EContentBlockerTestStatus, EContentBlockerVisualContentType, EContentBlockerVisualType, EXPORT_REMOVABLE_CONTENT_BLOCKER, EXTENDABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST, TRANSLATABLE_CONTENT_BLOCKER_TEMPLATE_ATTRIBUTES_LIST };
