/**
 * Merges the baseStyle with additionalStyle, giving precedence to additionalStyle.
 
* @param baseStyle 
 * @param additionalStyle 
 * @returns style
 */ function mergeStyles(baseStyle, additionalStyle) {
    return {
        ...baseStyle,
        ...additionalStyle
    };
}
export { mergeStyles };
