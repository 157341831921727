var ETechnicalDefinitionType;
(function(ETechnicalDefinitionType) {
    ETechnicalDefinitionType["Http"] = "http";
    ETechnicalDefinitionType["Local"] = "local";
    ETechnicalDefinitionType["Session"] = "session";
    ETechnicalDefinitionType["IndexedDb"] = "indexedDb";
})(ETechnicalDefinitionType || (ETechnicalDefinitionType = {}));
var ETechnicalDefinitionHostType;
(function(ETechnicalDefinitionHostType) {
    /**
     * nip.io
     */ ETechnicalDefinitionHostType["Main"] = "main";
    /**
     * .nip.io
     */ ETechnicalDefinitionHostType["MainPlusSubDomains"] = "main+subdomains";
    /**
     * feat.nip.io
     */ ETechnicalDefinitionHostType["Current"] = "current";
    /**
     * https://feat.nip.io
     */ ETechnicalDefinitionHostType["CurrentPlusProtocol"] = "current+protocol";
    /**
     * .feat.nip.io
     */ ETechnicalDefinitionHostType["CurrentPlusSubDomains"] = "current+subdomains";
})(ETechnicalDefinitionHostType || (ETechnicalDefinitionHostType = {}));
var ETechnicalDefinitionDurationUnit;
(function(ETechnicalDefinitionDurationUnit) {
    ETechnicalDefinitionDurationUnit["Seconds"] = "s";
    ETechnicalDefinitionDurationUnit["Minutes"] = "m";
    ETechnicalDefinitionDurationUnit["Hours"] = "h";
    ETechnicalDefinitionDurationUnit["Days"] = "d";
    ETechnicalDefinitionDurationUnit["Months"] = "mo";
    ETechnicalDefinitionDurationUnit["Years"] = "y";
})(ETechnicalDefinitionDurationUnit || (ETechnicalDefinitionDurationUnit = {}));
export { ETechnicalDefinitionType, ETechnicalDefinitionHostType, ETechnicalDefinitionDurationUnit };
