function moveArrayElementsToBeginning(source) {
    for(var _len = arguments.length, elements = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
        elements[_key - 1] = arguments[_key];
    }
    const elementSet = new Set(elements);
    const result = [];
    for (const item of elements){
        if (elementSet.has(item)) {
            result.push(item);
        }
    }
    for (const item of source){
        if (!elementSet.has(item)) {
            result.push(item);
        }
    }
    return result;
}
export { moveArrayElementsToBeginning };
