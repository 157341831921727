var EExtendsMergeStrategy;
(function(EExtendsMergeStrategy) {
    EExtendsMergeStrategy["Append"] = "append";
    EExtendsMergeStrategy["Overwrite"] = "overwrite";
    EExtendsMergeStrategy["Clear"] = "clear";
    /**
     * Introducing Keep as strategy to keep values from extended template.
     *
     * @see https://app.clickup.com/t/861m6y395 (CU-861m6y395)
     * */ EExtendsMergeStrategy["Keep"] = "keep";
})(EExtendsMergeStrategy || (EExtendsMergeStrategy = {}));
var ETemplateStatus;
(function(ETemplateStatus) {
    ETemplateStatus["Draft"] = "draft";
    ETemplateStatus["Published"] = "published";
})(ETemplateStatus || (ETemplateStatus = {}));
var ERcbTier;
(function(ERcbTier) {
    ERcbTier["Free"] = "free";
    ERcbTier["Pro"] = "pro";
})(ERcbTier || (ERcbTier = {}));
var EWhenOneOfCondition;
(function(EWhenOneOfCondition) {
    EWhenOneOfCondition["IsWordPressPluginOrThemeActive"] = "is-wordpress-plugin-or-theme-active";
})(EWhenOneOfCondition || (EWhenOneOfCondition = {}));
var ETemplateMetaDataVersionUpdateState;
(function(ETemplateMetaDataVersionUpdateState) {
    ETemplateMetaDataVersionUpdateState["Initial"] = "initial";
    ETemplateMetaDataVersionUpdateState["Update"] = "update";
    ETemplateMetaDataVersionUpdateState["Notify"] = "notify";
    ETemplateMetaDataVersionUpdateState["Removed"] = "removed";
    ETemplateMetaDataVersionUpdateState["NeverReleased"] = "never-released";
})(ETemplateMetaDataVersionUpdateState || (ETemplateMetaDataVersionUpdateState = {}));
export { EExtendsMergeStrategy, ERcbTier, ETemplateMetaDataVersionUpdateState, ETemplateStatus, EWhenOneOfCondition };
