import { useQuery } from "@tanstack/react-query";
import { createRelease } from "../api/real-cookie-banner";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
function useReleaseCreate(customRef, requestedIds) {
    const { rcbAuthenticationStore } = useStores();
    const releaseQuery = useQuery(QueryKeys.createRelease(customRef), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return createRelease({
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            body: requestedIds
        });
    }, {
        enabled: !!customRef,
        retry: false
    });
    return releaseQuery;
}
export { useReleaseCreate };
