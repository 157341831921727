import { useQuery } from "@tanstack/react-query";
import { getContentBlockerTemplateStatistics } from "../../api";
import { useStores } from "../../store";
import { QueryKeys } from "../../util/query-keys";
/**
 * Read content blocker statistics via RCB ContentBlocker API.
 */ function useContentBlockerTemplateStatistics() {
    let favouritesOnly = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.getContentBlockerStatistics(favouritesOnly), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getContentBlockerTemplateStatistics({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                favouritesOnly
            }
        });
    }, {
        retry: false,
        refetchOnMount: true
    });
}
export { useContentBlockerTemplateStatistics };
