import { useQuery } from "@tanstack/react-query";
import { getPreReleaseClients } from "../api";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
function usePreReleaseClients() {
    let clientUuid = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : undefined, enabled = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : undefined;
    const { rcbAuthenticationStore } = useStores();
    const preReleaseClientsQuery = useQuery(QueryKeys.getPreReleaseClients(enabled, clientUuid), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        const urlParameter = {};
        if (enabled !== undefined) {
            urlParameter.enabled = enabled;
        }
        if (clientUuid !== undefined) {
            urlParameter.clientUuid = clientUuid;
        }
        return getPreReleaseClients({
            params: urlParameter,
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            }
        });
    });
    return preReleaseClientsQuery;
}
export { usePreReleaseClients };
