var EIso639OneEnglish;
(function(EIso639OneEnglish) {
    EIso639OneEnglish["ab"] = "Abkhazian";
    EIso639OneEnglish["aa"] = "Afar";
    EIso639OneEnglish["af"] = "Afrikaans";
    EIso639OneEnglish["ak"] = "Akan";
    EIso639OneEnglish["sq"] = "Albanian";
    EIso639OneEnglish["am"] = "Amharic";
    EIso639OneEnglish["ar"] = "Arabic";
    EIso639OneEnglish["an"] = "Aragonese";
    EIso639OneEnglish["hy"] = "Armenian";
    EIso639OneEnglish["as"] = "Assamese";
    EIso639OneEnglish["av"] = "Avaric";
    EIso639OneEnglish["ae"] = "Avestan";
    EIso639OneEnglish["ay"] = "Aymara";
    EIso639OneEnglish["az"] = "Azerbaijani";
    EIso639OneEnglish["bm"] = "Bambara";
    EIso639OneEnglish["ba"] = "Bashkir";
    EIso639OneEnglish["eu"] = "Basque";
    EIso639OneEnglish["be"] = "Belarusian";
    EIso639OneEnglish["bn"] = "Bengali";
    EIso639OneEnglish["bi"] = "Bislama";
    EIso639OneEnglish["bs"] = "Bosnian";
    EIso639OneEnglish["br"] = "Breton";
    EIso639OneEnglish["bg"] = "Bulgarian";
    EIso639OneEnglish["my"] = "Burmese";
    EIso639OneEnglish["ca"] = "Catalan, Valencian";
    EIso639OneEnglish["ch"] = "Chamorro";
    EIso639OneEnglish["ce"] = "Chechen";
    EIso639OneEnglish["ny"] = "Chichewa, Chewa, Nyanja";
    EIso639OneEnglish["zh"] = "Chinese";
    EIso639OneEnglish["cu"] = "Church Slavic, Old Slavonic, Church Slavonic, Old Bulgarian, Old Church Slavonic";
    EIso639OneEnglish["cv"] = "Chuvash";
    EIso639OneEnglish["kw"] = "Cornish";
    EIso639OneEnglish["co"] = "Corsican";
    EIso639OneEnglish["cr"] = "Cree";
    EIso639OneEnglish["hr"] = "Croatian";
    EIso639OneEnglish["cs"] = "Czech";
    EIso639OneEnglish["da"] = "Danish";
    EIso639OneEnglish["dv"] = "Divehi, Dhivehi, Maldivian";
    EIso639OneEnglish["nl"] = "Dutch, Flemish";
    EIso639OneEnglish["dz"] = "Dzongkha";
    EIso639OneEnglish["en"] = "English";
    EIso639OneEnglish["eo"] = "Esperanto";
    EIso639OneEnglish["et"] = "Estonian";
    EIso639OneEnglish["ee"] = "Ewe";
    EIso639OneEnglish["fo"] = "Faroese";
    EIso639OneEnglish["fj"] = "Fijian";
    EIso639OneEnglish["fi"] = "Finnish";
    EIso639OneEnglish["fr"] = "French";
    EIso639OneEnglish["fy"] = "Western Frisian";
    EIso639OneEnglish["ff"] = "Fulah";
    EIso639OneEnglish["gd"] = "Gaelic, Scottish Gaelic";
    EIso639OneEnglish["gl"] = "Galician";
    EIso639OneEnglish["lg"] = "Ganda";
    EIso639OneEnglish["ka"] = "Georgian";
    EIso639OneEnglish["de"] = "German";
    EIso639OneEnglish["el"] = "Greek, Modern (1453–)";
    EIso639OneEnglish["kl"] = "Kalaallisut, Greenlandic";
    EIso639OneEnglish["gn"] = "Guarani";
    EIso639OneEnglish["gu"] = "Gujarati";
    EIso639OneEnglish["ht"] = "Haitian, Haitian Creole";
    EIso639OneEnglish["ha"] = "Hausa";
    EIso639OneEnglish["he"] = "Hebrew";
    EIso639OneEnglish["hz"] = "Herero";
    EIso639OneEnglish["hi"] = "Hindi";
    EIso639OneEnglish["ho"] = "Hiri Motu";
    EIso639OneEnglish["hu"] = "Hungarian";
    EIso639OneEnglish["is"] = "Icelandic";
    EIso639OneEnglish["io"] = "Ido";
    EIso639OneEnglish["ig"] = "Igbo";
    EIso639OneEnglish["id"] = "Indonesian";
    EIso639OneEnglish["ia"] = "Interlingua (International Auxiliary Language Association)";
    EIso639OneEnglish["ie"] = "Interlingue, Occidental";
    EIso639OneEnglish["iu"] = "Inuktitut";
    EIso639OneEnglish["ik"] = "Inupiaq";
    EIso639OneEnglish["ga"] = "Irish";
    EIso639OneEnglish["it"] = "Italian";
    EIso639OneEnglish["ja"] = "Japanese";
    EIso639OneEnglish["jv"] = "Javanese";
    EIso639OneEnglish["kn"] = "Kannada";
    EIso639OneEnglish["kr"] = "Kanuri";
    EIso639OneEnglish["ks"] = "Kashmiri";
    EIso639OneEnglish["kk"] = "Kazakh";
    EIso639OneEnglish["km"] = "Central Khmer";
    EIso639OneEnglish["ki"] = "Kikuyu, Gikuyu";
    EIso639OneEnglish["rw"] = "Kinyarwanda";
    EIso639OneEnglish["ky"] = "Kirghiz, Kyrgyz";
    EIso639OneEnglish["kv"] = "Komi";
    EIso639OneEnglish["kg"] = "Kongo";
    EIso639OneEnglish["ko"] = "Korean";
    EIso639OneEnglish["kj"] = "Kuanyama, Kwanyama";
    EIso639OneEnglish["ku"] = "Kurdish";
    EIso639OneEnglish["lo"] = "Lao";
    EIso639OneEnglish["la"] = "Latin";
    EIso639OneEnglish["lv"] = "Latvian";
    EIso639OneEnglish["li"] = "Limburgan, Limburger, Limburgish";
    EIso639OneEnglish["ln"] = "Lingala";
    EIso639OneEnglish["lt"] = "Lithuanian";
    EIso639OneEnglish["lu"] = "Luba-Katanga";
    EIso639OneEnglish["lb"] = "Luxembourgish, Letzeburgesch";
    EIso639OneEnglish["mk"] = "Macedonian";
    EIso639OneEnglish["mg"] = "Malagasy";
    EIso639OneEnglish["ms"] = "Malay";
    EIso639OneEnglish["ml"] = "Malayalam";
    EIso639OneEnglish["mt"] = "Maltese";
    EIso639OneEnglish["gv"] = "Manx";
    EIso639OneEnglish["mi"] = "Maori";
    EIso639OneEnglish["mr"] = "Marathi";
    EIso639OneEnglish["mh"] = "Marshallese";
    EIso639OneEnglish["mn"] = "Mongolian";
    EIso639OneEnglish["na"] = "Nauru";
    EIso639OneEnglish["nv"] = "Navajo, Navaho";
    EIso639OneEnglish["nd"] = "North Ndebele";
    EIso639OneEnglish["nr"] = "South Ndebele";
    EIso639OneEnglish["ng"] = "Ndonga";
    EIso639OneEnglish["ne"] = "Nepali";
    EIso639OneEnglish["no"] = "Norwegian";
    EIso639OneEnglish["nb"] = "Norwegian Bokm\xe5l";
    EIso639OneEnglish["nn"] = "Norwegian Nynorsk";
    EIso639OneEnglish["ii"] = "Sichuan Yi, Nuosu";
    EIso639OneEnglish["oc"] = "Occitan";
    EIso639OneEnglish["oj"] = "Ojibwa";
    EIso639OneEnglish["or"] = "Oriya";
    EIso639OneEnglish["om"] = "Oromo";
    EIso639OneEnglish["os"] = "Ossetian, Ossetic";
    EIso639OneEnglish["pi"] = "Pali";
    EIso639OneEnglish["ps"] = "Pashto, Pushto";
    EIso639OneEnglish["fa"] = "Persian";
    EIso639OneEnglish["pl"] = "Polish";
    EIso639OneEnglish["pt"] = "Portuguese";
    EIso639OneEnglish["pa"] = "Punjabi, Panjabi";
    EIso639OneEnglish["qu"] = "Quechua";
    EIso639OneEnglish["ro"] = "Romanian, Moldavian, Moldovan";
    EIso639OneEnglish["rm"] = "Romansh";
    EIso639OneEnglish["rn"] = "Rundi";
    EIso639OneEnglish["ru"] = "Russian";
    EIso639OneEnglish["se"] = "Northern Sami";
    EIso639OneEnglish["sm"] = "Samoan";
    EIso639OneEnglish["sg"] = "Sango";
    EIso639OneEnglish["sa"] = "Sanskrit";
    EIso639OneEnglish["sc"] = "Sardinian";
    EIso639OneEnglish["sr"] = "Serbian";
    EIso639OneEnglish["sn"] = "Shona";
    EIso639OneEnglish["sd"] = "Sindhi";
    EIso639OneEnglish["si"] = "Sinhala, Sinhalese";
    EIso639OneEnglish["sk"] = "Slovak";
    EIso639OneEnglish["sl"] = "Slovenian";
    EIso639OneEnglish["so"] = "Somali";
    EIso639OneEnglish["st"] = "Southern Sotho";
    EIso639OneEnglish["es"] = "Spanish, Castilian";
    EIso639OneEnglish["su"] = "Sundanese";
    EIso639OneEnglish["sw"] = "Swahili";
    EIso639OneEnglish["ss"] = "Swati";
    EIso639OneEnglish["sv"] = "Swedish";
    EIso639OneEnglish["tl"] = "Tagalog";
    EIso639OneEnglish["ty"] = "Tahitian";
    EIso639OneEnglish["tg"] = "Tajik";
    EIso639OneEnglish["ta"] = "Tamil";
    EIso639OneEnglish["tt"] = "Tatar";
    EIso639OneEnglish["te"] = "Telugu";
    EIso639OneEnglish["th"] = "Thai";
    EIso639OneEnglish["bo"] = "Tibetan";
    EIso639OneEnglish["ti"] = "Tigrinya";
    EIso639OneEnglish["to"] = "Tonga (Tonga Islands)";
    EIso639OneEnglish["ts"] = "Tsonga";
    EIso639OneEnglish["tn"] = "Tswana";
    EIso639OneEnglish["tr"] = "Turkish";
    EIso639OneEnglish["tk"] = "Turkmen";
    EIso639OneEnglish["tw"] = "Twi";
    EIso639OneEnglish["ug"] = "Uighur, Uyghur";
    EIso639OneEnglish["uk"] = "Ukrainian";
    EIso639OneEnglish["ur"] = "Urdu";
    EIso639OneEnglish["uz"] = "Uzbek";
    EIso639OneEnglish["ve"] = "Venda";
    EIso639OneEnglish["vi"] = "Vietnamese";
    EIso639OneEnglish["vo"] = "Volap\xfck";
    EIso639OneEnglish["wa"] = "Walloon";
    EIso639OneEnglish["cy"] = "Welsh";
    EIso639OneEnglish["wo"] = "Wolof";
    EIso639OneEnglish["xh"] = "Xhosa";
    EIso639OneEnglish["yi"] = "Yiddish";
    EIso639OneEnglish["yo"] = "Yoruba";
    EIso639OneEnglish["za"] = "Zhuang, Chuang";
    EIso639OneEnglish["zu"] = "Zulu";
})(EIso639OneEnglish || (EIso639OneEnglish = {}));
export { EIso639OneEnglish };
