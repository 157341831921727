/**
 * Debounces a function so it only executes after a specified period of waiting time has elapsed
 * since the last time it was invoked. This is useful for optimizing performance by limiting
 * the number of times a function is called during repeated events, such as window resizing,
 * scroll events, or keystrokes.
 *
 * @param func - The function to debounce. It is the function that you want to delay
 * execution of until after waitMilliSeconds have elapsed since the last time it was invoked.
 *
 * @param waitMilliSeconds - The number of milliseconds to wait before executing the
 * function after it is called. If the debounced function is called again before this time has
 * elapsed, the timer is reset and the function will not be executed until the waiting time has
 * elapsed after the latest call.
 *
 * @returns A new, debounced version of the input function. This function can be called
 * multiple times, but will only execute once per the specified waiting period, ignoring subsequent
 * calls until the waiting period is over and then using the latest arguments passed to it.
 */ function debounce(func, waitMilliSeconds) {
    let timeout;
    return function executedFunction() {
        for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
            args[_key] = arguments[_key];
        }
        const later = ()=>{
            clearTimeout(timeout);
            func(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, waitMilliSeconds);
    };
}
export { debounce };
