const RELEASE_HIDDEN_DIFF_PROPS = [
    "id",
    "createdAt",
    "hash",
    "nextId",
    "preId",
    "release",
    "releaseId",
    "status",
    "translationInfo"
];
Object.freeze(RELEASE_HIDDEN_DIFF_PROPS);
export { RELEASE_HIDDEN_DIFF_PROPS };
