import { createLocalizationFactory } from "@devowl-wp/react-frontend";
// Only needed once per `window`
let FACTORY_MEMO;
function get() {
    return FACTORY_MEMO ? FACTORY_MEMO : FACTORY_MEMO = createLocalizationFactory();
}
const useTranslation = function() {
    for(var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++){
        args[_key] = arguments[_key];
    }
    return get().useTranslation(...args);
};
export { useTranslation };
