const service = {
    tier: {
        free: {
            color: "green"
        },
        pro: {
            color: "blue"
        }
    }
};
export { service };
