import { useQuery } from "@tanstack/react-query";
import { getServiceTemplateBy } from "../../api";
import { useStores } from "../../store";
import { QueryKeys } from "../../util/query-keys";
/**
 * Read service template by ID via RCB Service API.
 */ function useServiceTemplateById(id) {
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.getServiceById(id), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getServiceTemplateBy({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                id
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!id
    });
}
/**
 * Read service template by ID via RCB Service API.
 */ function useServiceTemplateByIdentifier(identifier) {
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.getServiceByIdentifier(identifier), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getServiceTemplateBy({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                identifier
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!identifier
    });
}
export { useServiceTemplateById, useServiceTemplateByIdentifier };
