/**
 * Compare two strings, handling null and undefined values.
 *
 * @param a - The first string to compare.
 * @param b - The second string to compare.
 * @returns A negative number if `a` should come before `b`, a positive number if `b` should come before `a`,
 *          and 0 if they are equal or both are null/undefined.
 */ function compareStrings(a, b) {
    // Handle null and undefined values
    if (a === null || a === undefined) {
        return b === null || b === undefined ? 0 : -1; // Treat a as "smaller" than b
    }
    if (b === null || b === undefined) {
        return 1; // Treat b as "smaller" than a
    }
    // Compare non-null and non-undefined strings using localeCompare
    return a.localeCompare(b);
}
/**
 * Trims a string to a specified maximum length, adding ellipsis (...) if necessary.
 * @param str - The string to trim.
 * @param  maxLength - The maximum length of the trimmed string.
 * @returns The trimmed string.
 */ function trimStringWithEllipsis(str, maxLength) {
    if (str.length <= maxLength) {
        return str;
    }
    const start = str.slice(0, maxLength / 2);
    const end = str.slice(str.length - maxLength / 2 + 3);
    return `${start}...${end}`;
}
export { compareStrings, trimStringWithEllipsis };
