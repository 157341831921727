import { ETechnicalDefinitionDurationUnit, ETechnicalDefinitionHostType, ETechnicalDefinitionType } from "@devowl-wp/api-real-cookie-banner";
import { isHost, isUrl } from "@devowl-wp/react-frontend";
const KNOWN_HTTP_COOKIES = [
    "wordpress_sec_",
    "wordpress_test_cookie",
    "wp-settings-time-1",
    "wordpress_logged_in_",
    "comment_author_",
    "comment_author_email_",
    "comment_author_url_"
];
const KNOWN_SESSION_STORAGE = [
    "wpEmojiSettingsSupports",
    "wp-api-schema-modelhttp"
];
const KNOWN_LOCAL_STORAGE = [
    "show_debug_overlay",
    "prefStore"
];
Object.freeze(KNOWN_HTTP_COOKIES);
Object.freeze(KNOWN_SESSION_STORAGE);
Object.freeze(KNOWN_LOCAL_STORAGE);
/**
 * Check if given Name is a known value. (startsWith)
 * @param givenName e.g. "wordpress_sec_asdf"
 * @returns true or false
 */ function isDefaultHttpCookie(givenName) {
    return KNOWN_HTTP_COOKIES.some((knownName)=>givenName.startsWith(knownName));
}
/**
 * Check if given Name is a known value. (startsWith)
 * @param givenName e.g. "wpEmojiSettingsSupports"
 * @returns true or false
 */ function isDefaultSessionStorageItem(givenName) {
    return KNOWN_SESSION_STORAGE.some((knownName)=>givenName.startsWith(knownName));
}
/**
 * Check if given Name is a known value. (startsWith)
 * @param givenName e.g. "prefStore"
 * @returns true or false
 */ function isDefaultLocalStorageItem(givenName) {
    return KNOWN_LOCAL_STORAGE.some((knownName)=>givenName.startsWith(knownName));
}
/**
 * Check host via given ETechnicalDefinitionType
 * @param type ETechnicalDefinitionType
 * @param host string
 * @returns true or false
 */ function isValidHostByType(type, host) {
    if (!host) {
        return false;
    }
    return ([
        ETechnicalDefinitionType.Local,
        ETechnicalDefinitionType.Session,
        ETechnicalDefinitionType.IndexedDb
    ].indexOf(type) > -1 ? isUrl(host) : isHost(host)) || host === "" && type !== ETechnicalDefinitionType.Http || Object.values(ETechnicalDefinitionHostType).indexOf(host) > -1;
}
/**
 * Parse http cookies from a raw string, copied from a chromium based browser.
 *
 * @param raw copied from browser
 * @param siteHost top level site domain, like "devowl.io"
 * @returns parsed cookies as array
 */ function parseHttpCookies(raw, siteHost, cookieCreated) {
    const lines = raw.trim().split("\n");
    const cookies = lines.map((line)=>{
        const [name, value, host, path, durationString] = line.split("\t");
        const isSessionDuration = durationString === "Session";
        const expires = !isSessionDuration ? durationString ? new Date(durationString) : null : null;
        const { duration, durationUnit } = calculateDuration(expires, cookieCreated);
        if (!name) {
            return undefined;
        }
        return {
            name,
            value,
            host: determineHttpCookieHostType(host, siteHost),
            path,
            expires,
            isSessionDuration,
            duration,
            durationUnit,
            isKnownDefault: isDefaultHttpCookie(name)
        };
    }).filter(Boolean);
    return cookies;
}
/**
 * Parse local and session storage information from a raw string, copied from a chromium based browser.
 *
 * @param raw copied from browser
 * @param type cookie type
 * @param originHost origin (information can be retrieved from web dev tools, when opening storage entries)
 * @param siteHost top level site domain, like "devowl.io"
 * @returns parsed storage information as array
 */ function parseStorageItems(raw, type, originHost, siteHost) {
    const lines = (raw === null || raw === void 0 ? void 0 : raw.trim().split("\n")) || [];
    const host = determineStorageHostType(originHost, siteHost);
    const result = [];
    for (const line of lines){
        const [key, rawValue] = line.split("\t");
        let isDefault = false;
        switch(type){
            case ETechnicalDefinitionType.Local:
                isDefault = isDefaultLocalStorageItem(key);
                break;
            case ETechnicalDefinitionType.Session:
                isDefault = isDefaultSessionStorageItem(key);
                break;
            default:
                break;
        }
        const parsedValue = tryParseValue(rawValue);
        if (key) {
            result.push({
                key,
                value: parsedValue,
                host,
                type,
                isKnownDefault: isDefault
            });
        }
    }
    return result;
}
/**
 * Try to parse a given raw value.
 *
 * @param rawValue any string based value
 * @returns parsed and typed.
 */ function tryParseValue(rawValue) {
    try {
        // Try to parse the value as JSON.
        return JSON.parse(rawValue);
    } catch (error) {
        // If it's not JSON, check if it's a boolean or number.
        if (rawValue === "true") {
            return true;
        } else if (rawValue === "false") {
            return false;
        } else if (!isNaN(Number(rawValue))) {
            return Number(rawValue);
        } else {
            return rawValue;
        }
    }
}
/**
 * Try to determine a possible ETechnicalDefinitionHostType from cookieHost and siteHost.
 *
 * @param cookieHost host information defined on cookie, attribute domain
 * @param siteHost top level site domain, like "devowl.io"
 * @returns ETechnicalDefinitionHostType or just the cookieHost
 */ function determineHttpCookieHostType(cookieHost, siteHost) {
    if (cookieHost && siteHost) {
        if (cookieHost === siteHost) {
            // devowl.io === devowl.io
            return ETechnicalDefinitionHostType.Main;
        }
        if (cookieHost === `.${siteHost}`) {
            // .devowl.io === .devowl.io
            return ETechnicalDefinitionHostType.MainPlusSubDomains;
        }
        if (cookieHost.endsWith(siteHost)) {
            if (cookieHost.startsWith(".")) {
                // cookieHost = ".blog.devowl.io"
                return ETechnicalDefinitionHostType.CurrentPlusSubDomains;
            }
            // blog.devowl.io
            return ETechnicalDefinitionHostType.Current;
        }
    }
    // if none of the conditions match, return the cookieHost as is
    return cookieHost;
}
/**
 * Try to determine a possible ETechnicalDefinitionHostType from origin and siteHost.
 *
 * @param origin (information can be retrieved from web dev tools, when opening storage entries)
 * @param siteHost top level site domain, like "devowl.io"
 * @returns ETechnicalDefinitionHostType or just the cookieHost
 */ function determineStorageHostType(origin, siteHost) {
    if (origin && siteHost && origin.startsWith("http") && origin.endsWith(siteHost)) {
        // https://devowl.io
        // https://www.devowl.io
        // https://blog.devowl.io
        return ETechnicalDefinitionHostType.CurrentPlusProtocol;
    }
    // If none of the conditions match, return the origin as is
    return origin;
}
/**
 * Quick test, if this raw string could be a possible candidate for parsing of storage items.
 * @param raw copied from browser
 * @returns true or false
 */ function isStorageItemRawDataSuitable(raw) {
    if (typeof raw !== "string") {
        return false;
    }
    const lines = raw.trim().split("\n");
    for (const line of lines){
        const parts = line.split("\t");
        // Ensure there are at least two parts: a key and a value, but also a single key is valid and a key value and an empty tab
        if (![
            1,
            2,
            3
        ].includes(parts.length)) {
            return false;
        }
    }
    return true;
}
/**
 * Quick test, if this raw string could be a possible candidate for parsing of http cookies.
 * @param raw copied from browser
 * @returns true or false
 */ function isCookieRawDataSuitable(raw) {
    if (typeof raw !== "string") {
        return false;
    }
    const lines = raw.trim().split("\n");
    for (const line of lines){
        const parts = line.split("\t");
        // Ensure there are at least 5 parts for each line.
        if (parts.length < 5) {
            return false;
        }
    }
    return true;
}
/**
 * Calculate a proper duration via cookie expiration date and current time.
 *
 * @param expires cookie expires date
 * @returns duration and durationUnit, based on internal rules to choose proper unit
 */ function calculateDuration(expirationDate, cookieCreated) {
    if (!expirationDate) return {
        duration: null,
        durationUnit: null
    };
    // units, based on seconds
    // month and year calculations based on averages
    const minute = 60;
    const hour = minute * 60;
    const day = hour * 24;
    const month = day * 30;
    const durationInSeconds = (expirationDate.getTime() - cookieCreated.getTime()) / 1000;
    const daysPerMonth = 30;
    const hoursPerDay = 24;
    const minutesPerHour = 60;
    // Threshold factor at which the value is rounded up to the next larger unit.
    const threshold = 0.9;
    let duration = 1;
    let unit = ETechnicalDefinitionDurationUnit.Minutes;
    if (durationInSeconds >= minute) {
        duration = durationInSeconds / minute;
        unit = ETechnicalDefinitionDurationUnit.Minutes;
        if (durationInSeconds >= hour) {
            duration = durationInSeconds / hour;
            unit = ETechnicalDefinitionDurationUnit.Hours;
            if (durationInSeconds >= day) {
                duration = durationInSeconds / day;
                unit = ETechnicalDefinitionDurationUnit.Days;
                if (durationInSeconds >= month) {
                    // calculate months
                    duration = durationInSeconds / month;
                    unit = ETechnicalDefinitionDurationUnit.Months;
                }
            }
        }
    }
    switch(unit){
        case ETechnicalDefinitionDurationUnit.Minutes:
            if (duration > minutesPerHour * threshold) {
                duration = 1;
                unit = ETechnicalDefinitionDurationUnit.Hours;
            }
            break;
        case ETechnicalDefinitionDurationUnit.Hours:
            if (duration > hoursPerDay * threshold) {
                duration = 1;
                unit = ETechnicalDefinitionDurationUnit.Days;
            }
            break;
        case ETechnicalDefinitionDurationUnit.Days:
            if (duration > daysPerMonth * threshold) {
                duration = 1;
                unit = ETechnicalDefinitionDurationUnit.Months;
            }
            break;
    }
    duration = Math.ceil(duration); // always ceil
    return {
        duration,
        durationUnit: unit
    };
}
export { calculateDuration, isCookieRawDataSuitable, isStorageItemRawDataSuitable, isValidHostByType, parseHttpCookies, parseStorageItems };
