var EStorageProvider;
(function(EStorageProvider) {
    EStorageProvider["Backblaze"] = "backblaze-b2";
    EStorageProvider["Scaleway"] = "scaleway-s3";
})(EStorageProvider || (EStorageProvider = {}));
var EStorageClass;
(function(EStorageClass) {
    EStorageClass["Standard"] = "STANDARD";
    EStorageClass["Glacier"] = "GLACIER";
})(EStorageClass || (EStorageClass = {}));
var EStorageImageType;
(function(EStorageImageType) {
    EStorageImageType["Gif"] = "gif";
    EStorageImageType["Jpeg"] = "jpeg";
    EStorageImageType["Png"] = "png";
    EStorageImageType["Svg"] = "svg";
    EStorageImageType["Webp"] = "webp";
})(EStorageImageType || (EStorageImageType = {}));
const STORAGE_FILE_TYPES_IMAGE = [
    "gif",
    "jpeg",
    "png",
    "webp"
];
Object.freeze(STORAGE_FILE_TYPES_IMAGE);
export { STORAGE_FILE_TYPES_IMAGE, EStorageImageType, EStorageProvider, EStorageClass };
