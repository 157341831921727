var EIso639OneGerman;
(function(EIso639OneGerman) {
    EIso639OneGerman["ab"] = "Abchasisch";
    EIso639OneGerman["aa"] = "Afar";
    EIso639OneGerman["af"] = "Afrikaans";
    EIso639OneGerman["ak"] = "Akan";
    EIso639OneGerman["sq"] = "Albanisch";
    EIso639OneGerman["am"] = "Amharisch";
    EIso639OneGerman["ar"] = "Arabisch";
    EIso639OneGerman["an"] = "Aragonesisch";
    EIso639OneGerman["hy"] = "Armenisch";
    EIso639OneGerman["as"] = "Assamesisch";
    EIso639OneGerman["av"] = "Avarisch";
    EIso639OneGerman["ae"] = "Avestisch";
    EIso639OneGerman["ay"] = "Aymara";
    EIso639OneGerman["az"] = "Aserbaidschanisch";
    EIso639OneGerman["bm"] = "Bambara";
    EIso639OneGerman["ba"] = "Baschkirisch";
    EIso639OneGerman["eu"] = "Baskisch";
    EIso639OneGerman["be"] = "Belarussisch";
    EIso639OneGerman["bn"] = "Bengalisch";
    EIso639OneGerman["bi"] = "Bislama";
    EIso639OneGerman["bs"] = "Bosnisch";
    EIso639OneGerman["br"] = "Bretonisch";
    EIso639OneGerman["bg"] = "Bulgarisch";
    EIso639OneGerman["my"] = "Birmanisch";
    EIso639OneGerman["ca"] = "Katalanisch , Valencianisch";
    EIso639OneGerman["ch"] = "Chamorro";
    EIso639OneGerman["ce"] = "Tschetschenisch";
    EIso639OneGerman["ny"] = "Chichewa";
    EIso639OneGerman["zh"] = "Chinesisch";
    EIso639OneGerman["cv"] = "Tschuwaschisch";
    EIso639OneGerman["kw"] = "Kornisch";
    EIso639OneGerman["co"] = "Korsisch";
    EIso639OneGerman["cr"] = "Cree";
    EIso639OneGerman["hr"] = "Kroatisch";
    EIso639OneGerman["cs"] = "Tschechisch";
    EIso639OneGerman["da"] = "D\xe4nisch";
    EIso639OneGerman["dv"] = "Dhivehi";
    EIso639OneGerman["nl"] = "Niederl\xe4ndisch , Belgisches Niederl\xe4ndisch";
    EIso639OneGerman["dz"] = "Dzongkha";
    EIso639OneGerman["en"] = "Englisch";
    EIso639OneGerman["eo"] = "Esperanto";
    EIso639OneGerman["et"] = "Estnisch";
    EIso639OneGerman["ee"] = "Ewe";
    EIso639OneGerman["fo"] = "F\xe4r\xf6isch";
    EIso639OneGerman["fj"] = "Fidschi";
    EIso639OneGerman["fi"] = "Finnisch";
    EIso639OneGerman["fr"] = "Franz\xf6sisch";
    EIso639OneGerman["ff"] = "Fulfulde";
    EIso639OneGerman["gl"] = "Galicisch , Galegisch";
    EIso639OneGerman["ka"] = "Georgisch";
    EIso639OneGerman["de"] = "Deutsch";
    EIso639OneGerman["el"] = "Griechisch";
    EIso639OneGerman["gn"] = "Guaran\xed";
    EIso639OneGerman["gu"] = "Gujarati";
    EIso639OneGerman["ht"] = "Haitianisch";
    EIso639OneGerman["ha"] = "Hausa";
    EIso639OneGerman["he"] = "Hebr\xe4isch";
    EIso639OneGerman["hz"] = "Otjiherero";
    EIso639OneGerman["hi"] = "Hindi";
    EIso639OneGerman["ho"] = "Hiri Motu";
    EIso639OneGerman["hu"] = "Ungarisch";
    EIso639OneGerman["ia"] = "Interlingua";
    EIso639OneGerman["id"] = "Indonesisch";
    EIso639OneGerman["ie"] = "Interlingue";
    EIso639OneGerman["ga"] = "Irisch";
    EIso639OneGerman["ig"] = "Igbo";
    EIso639OneGerman["ik"] = "Inupiaq";
    EIso639OneGerman["io"] = "Ido";
    EIso639OneGerman["is"] = "Isl\xe4ndisch";
    EIso639OneGerman["it"] = "Italienisch";
    EIso639OneGerman["iu"] = "Inuktitut";
    EIso639OneGerman["ja"] = "Japanisch";
    EIso639OneGerman["jv"] = "Javanisch";
    EIso639OneGerman["kl"] = "Gr\xf6nl\xe4ndisch , Kalaallisut";
    EIso639OneGerman["kn"] = "Kannada";
    EIso639OneGerman["kr"] = "Kanuri";
    EIso639OneGerman["ks"] = "Kashmiri";
    EIso639OneGerman["kk"] = "Kasachisch";
    EIso639OneGerman["km"] = "Khmer";
    EIso639OneGerman["ki"] = "Kikuyu";
    EIso639OneGerman["rw"] = "Kinyarwanda , Ruandisch";
    EIso639OneGerman["ky"] = "Kirgisisch";
    EIso639OneGerman["kv"] = "Komi";
    EIso639OneGerman["kg"] = "Kikongo";
    EIso639OneGerman["ko"] = "Koreanisch";
    EIso639OneGerman["ku"] = "Kurdisch";
    EIso639OneGerman["kj"] = "oshiKwanyama";
    EIso639OneGerman["la"] = "Latein";
    EIso639OneGerman["lb"] = "Luxemburgisch";
    EIso639OneGerman["lg"] = "Luganda";
    EIso639OneGerman["li"] = "Limburgisch , S\xfcdniederfr\xe4nkisch";
    EIso639OneGerman["ln"] = "Ling\xe1la";
    EIso639OneGerman["lo"] = "Laotisch";
    EIso639OneGerman["lt"] = "Litauisch";
    EIso639OneGerman["lu"] = "Kiluba";
    EIso639OneGerman["lv"] = "Lettisch";
    EIso639OneGerman["gv"] = "Manx , Manx-G\xe4lisch";
    EIso639OneGerman["mk"] = "Mazedonisch";
    EIso639OneGerman["mg"] = "Malagasy , Malagassi";
    EIso639OneGerman["ms"] = "Malaiisch";
    EIso639OneGerman["ml"] = "Malayalam";
    EIso639OneGerman["mt"] = "Maltesisch";
    EIso639OneGerman["mi"] = "Maori";
    EIso639OneGerman["mr"] = "Marathi";
    EIso639OneGerman["mh"] = "Marshallesisch";
    EIso639OneGerman["mn"] = "Mongolisch";
    EIso639OneGerman["na"] = "Nauruisch";
    EIso639OneGerman["nv"] = "Navajo";
    EIso639OneGerman["nd"] = "Nord-Ndebele";
    EIso639OneGerman["ne"] = "Nepali";
    EIso639OneGerman["ng"] = "Ndonga";
    EIso639OneGerman["nb"] = "Bokm\xe5l";
    EIso639OneGerman["nn"] = "Nynorsk";
    EIso639OneGerman["no"] = "Norwegisch";
    EIso639OneGerman["ii"] = "Yi";
    EIso639OneGerman["nr"] = "S\xfcd-Ndebele";
    EIso639OneGerman["oc"] = "Okzitanisch";
    EIso639OneGerman["oj"] = "Ojibwe";
    EIso639OneGerman["cu"] = "Kirchenslawisch , Altkirchenslawisch";
    EIso639OneGerman["om"] = "Oromo";
    EIso639OneGerman["or"] = "Oriya";
    EIso639OneGerman["os"] = "Ossetisch";
    EIso639OneGerman["pa"] = "Panjabi , Pandschabi";
    EIso639OneGerman["pi"] = "Pali";
    EIso639OneGerman["fa"] = "Persisch";
    EIso639OneGerman["pl"] = "Polnisch";
    EIso639OneGerman["ps"] = "Paschtunisch";
    EIso639OneGerman["pt"] = "Portugiesisch";
    EIso639OneGerman["qu"] = "Quechua";
    EIso639OneGerman["rm"] = "B\xfcndnerromanisch , Romanisch";
    EIso639OneGerman["rn"] = "Kirundi";
    EIso639OneGerman["ro"] = "Rum\xe4nisch";
    EIso639OneGerman["ru"] = "Russisch";
    EIso639OneGerman["sa"] = "Sanskrit";
    EIso639OneGerman["sc"] = "Sardisch";
    EIso639OneGerman["sd"] = "Sindhi";
    EIso639OneGerman["se"] = "Nordsamisch";
    EIso639OneGerman["sm"] = "Samoanisch";
    EIso639OneGerman["sg"] = "Sango";
    EIso639OneGerman["sr"] = "Serbisch";
    EIso639OneGerman["gd"] = "Schottisch-g\xe4lisch";
    EIso639OneGerman["sn"] = "Shona";
    EIso639OneGerman["si"] = "Singhalesisch";
    EIso639OneGerman["sk"] = "Slowakisch";
    EIso639OneGerman["sl"] = "Slowenisch";
    EIso639OneGerman["so"] = "Somali";
    EIso639OneGerman["st"] = "Sesotho , S\xfcd-Sotho";
    EIso639OneGerman["es"] = "Spanisch , Kastilisch";
    EIso639OneGerman["su"] = "Sundanesisch";
    EIso639OneGerman["sw"] = "Swahili";
    EIso639OneGerman["ss"] = "Siswati";
    EIso639OneGerman["sv"] = "Schwedisch";
    EIso639OneGerman["ta"] = "Tamil";
    EIso639OneGerman["te"] = "Telugu";
    EIso639OneGerman["tg"] = "Tadschikisch";
    EIso639OneGerman["th"] = "Thai";
    EIso639OneGerman["ti"] = "Tigrinya";
    EIso639OneGerman["bo"] = "Tibetisch";
    EIso639OneGerman["tk"] = "Turkmenisch";
    EIso639OneGerman["tl"] = "Tagalog";
    EIso639OneGerman["tn"] = "Setswana";
    EIso639OneGerman["to"] = "Tongaisch";
    EIso639OneGerman["tr"] = "T\xfcrkisch";
    EIso639OneGerman["ts"] = "Xitsonga";
    EIso639OneGerman["tt"] = "Tatarisch";
    EIso639OneGerman["tw"] = "Twi";
    EIso639OneGerman["ty"] = "Tahitianisch , Tahitisch";
    EIso639OneGerman["ug"] = "Uigurisch";
    EIso639OneGerman["uk"] = "Ukrainisch";
    EIso639OneGerman["ur"] = "Urdu";
    EIso639OneGerman["uz"] = "Usbekisch";
    EIso639OneGerman["ve"] = "Tshivenda";
    EIso639OneGerman["vi"] = "Vietnamesisch";
    EIso639OneGerman["vo"] = "Volap\xfck";
    EIso639OneGerman["wa"] = "Wallonisch";
    EIso639OneGerman["cy"] = "Walisisch";
    EIso639OneGerman["wo"] = "Wolof";
    EIso639OneGerman["fy"] = "Westfriesisch";
    EIso639OneGerman["xh"] = "isiXhosa";
    EIso639OneGerman["yi"] = "Jiddisch";
    EIso639OneGerman["yo"] = "Yoruba";
    EIso639OneGerman["za"] = "Zhuang";
    EIso639OneGerman["zu"] = "isiZulu";
})(EIso639OneGerman || (EIso639OneGerman = {}));
export { EIso639OneGerman };
