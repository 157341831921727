import { EExtendsMergeStrategy } from "@devowl-wp/api-real-cookie-banner";
/**
 * By default no strategies are disabled, except when parameter `isArray` is set to `true`.
 */ function getDisableStrategies() {
    let isArray = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false, forceDisable = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    const defaultDisabledStrategies = isArray ? [] : [
        EExtendsMergeStrategy.Append
    ];
    const additionalDisabledStrategies = forceDisable || [];
    return [
        ...new Set([
            ...defaultDisabledStrategies,
            ...additionalDisabledStrategies
        ])
    ];
}
function clearFieldsByMergeStrategies(template) {
    if (((template === null || template === void 0 ? void 0 : template.extendedTemplateId) || (template === null || template === void 0 ? void 0 : template.extends) || template.extendsIdentifier) && (template === null || template === void 0 ? void 0 : template.extendedMergeStrategies)) {
        for (const key of Object.keys(template.extendedMergeStrategies)){
            if ([
                EExtendsMergeStrategy.Clear,
                EExtendsMergeStrategy.Keep
            ].includes(template.extendedMergeStrategies[key])) {
                delete template[key];
            }
        }
    }
}
export { clearFieldsByMergeStrategies, getDisableStrategies };
