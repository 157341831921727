const isScrollable = (node)=>{
    if (!(node instanceof HTMLElement || node instanceof SVGElement)) {
        return false;
    }
    const style = getComputedStyle(node);
    return [
        "overflow",
        "overflow-x",
        "overflow-y"
    ].some((propertyName)=>{
        const value = style.getPropertyValue(propertyName);
        return value === "auto" || value === "scroll";
    });
};
/**
 * @see https://stackoverflow.com/a/76535387/5506547
 */ const getScrollParent = (node)=>{
    let currentParent = node.parentElement;
    while(currentParent){
        if (isScrollable(currentParent)) {
            return currentParent;
        }
        currentParent = currentParent.parentElement;
    }
    return document.scrollingElement || document.documentElement;
};
export { getScrollParent };
