function normalizeUrlWithProtocol(url) {
    const protocols = [
        "http://",
        "https://"
    ];
    for (const protocol of protocols){
        if ((protocol === null || protocol === void 0 ? void 0 : protocol.indexOf(url)) === 0 || url.indexOf(`${protocol}`) === 0) {
            return url.trim();
        }
    }
    return `https://${url}`.trim();
}
export { normalizeUrlWithProtocol };
