const SEMVER_REGEX = /^(?<major>\d{1,4})\.(?<minor>\d{1,4})\.(?<patch>\d{1,4})(?:-(?<prerelease>\d{0,7}))?$/s;
function mapSemverToVersion(semver) {
    var _matches_groups, _matches_groups1, _matches_groups2, _matches_groups3;
    const matches = SEMVER_REGEX.exec(semver);
    if (!matches) {
        return semver;
    }
    const major = ((_matches_groups = matches.groups) === null || _matches_groups === void 0 ? void 0 : _matches_groups.major) || "0000";
    const minor = ((_matches_groups1 = matches.groups) === null || _matches_groups1 === void 0 ? void 0 : _matches_groups1.minor) || "0000";
    const patch = ((_matches_groups2 = matches.groups) === null || _matches_groups2 === void 0 ? void 0 : _matches_groups2.patch) || "0000";
    const prerelease = ((_matches_groups3 = matches.groups) === null || _matches_groups3 === void 0 ? void 0 : _matches_groups3.prerelease) || "";
    return `${major.padStart(4, "0")}${minor.padStart(4, "0")}${patch.padStart(4, "0")}${prerelease.padStart(7, "0")}`;
}
function mapVersionToSemver(version) {
    if (!/^\d{19}$/.test(version)) {
        return version;
    }
    const major = String(Number(version.substring(0, 4)));
    const minor = String(Number(version.substring(4, 8)));
    const patch = String(Number(version.substring(8, 12)));
    const prerelease = String(Number(version.substring(12)));
    let output = `${major}.${minor}.${patch}`;
    if (prerelease !== "" && prerelease !== "0") {
        output += `-${prerelease}`;
    }
    return output;
}
export { mapSemverToVersion, mapVersionToSemver };
