import { QueryClient } from "@tanstack/react-query";
function createQueryClient() {
    return new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                retry: false
            }
        }
    });
}
export { createQueryClient };
