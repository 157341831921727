import { extractVariableNamesFromScript } from "./scripts";
function validateScriptVariables(scriptValue, dynamicFields, t) {
    const scriptVars = extractVariableNamesFromScript(scriptValue);
    const definedVars = (dynamicFields === null || dynamicFields === void 0 ? void 0 : dynamicFields.map((field)=>field.name).filter(Boolean)) || [];
    const missing = [];
    if (scriptVars.length > 0) {
        scriptVars.filter((scriptVar)=>{
            if (!definedVars.includes(scriptVar)) {
                missing.push(scriptVar);
            }
        });
    }
    if (missing.length > 0) {
        return Promise.reject(new Error(t("Missing dynamic field(s): {{missing}}", {
            missing: missing.join(", ")
        })));
    } else {
        return Promise.resolve();
    }
}
export { validateScriptVariables };
