import { ETechnicalDefinitionType } from "@devowl-wp/api-real-cookie-banner";
function useServiceTemplateTechnicalDefinitionTypeKeys() {
    return Object.keys(ETechnicalDefinitionType).map((key)=>{
        if (isNaN(Number(key))) {
            return ETechnicalDefinitionType[key];
        }
        return undefined;
    }).filter(Boolean);
}
function getServiceTemplateTechnicalDefinitionTypes(t) {
    return useServiceTemplateTechnicalDefinitionTypeKeys().map((typeKey)=>{
        return {
            value: typeKey,
            label: useTranslateTypeKey(t, typeKey)
        };
    });
}
function useTranslateTypeKey(t, typeKey) {
    switch(typeKey){
        case ETechnicalDefinitionType.Http:
            return t("HTTP Cookie");
        case ETechnicalDefinitionType.IndexedDb:
            return t("IndexedDB");
        case ETechnicalDefinitionType.Local:
            return t("Local Storage");
        case ETechnicalDefinitionType.Session:
            return t("Session Storage");
        default:
            return t("Type not found!");
    }
}
export { useServiceTemplateTechnicalDefinitionTypeKeys, getServiceTemplateTechnicalDefinitionTypes, useTranslateTypeKey };
