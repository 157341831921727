/**
 * Will return a fetch function, calling given host and api version.
 * String is concatenated to a path like: https://restApiHost/1
 */ function apiFetch(restApiHost, restApiVersion) {
    return async function(location, options) {
        const url = new URL(`${restApiHost}/${restApiVersion}${location.path}`);
        if (options.params) {
            Object.entries(options.params).forEach((param)=>{
                let [k, v] = param;
                return url.searchParams.append(k, v);
            });
        }
        const controller = new AbortController();
        const abortTimeoutId = setTimeout(()=>controller.abort(), options.timeout ? options.timeout : 20000);
        const resource = url.toString();
        const body = options.body ? JSON.stringify(options.body) : undefined;
        const response = await fetch(resource, {
            method: location.method,
            headers: {
                Accept: "application/json",
                "content-type": "application/json",
                "Accept-Language": options.language ? options.language : "en",
                ...options === null || options === void 0 ? void 0 : options.headers
            },
            body,
            signal: options.signal ? options.signal : controller.signal,
            cache: "no-cache"
        });
        clearTimeout(abortTimeoutId);
        const result = await getJsonResponse(response);
        if (response.ok) {
            return result;
        } else if (response.status === 401) {
            const error = {
                code: String(response.status),
                message: response.statusText || "Unauthorized"
            };
            // Trigger session destroy via invalidSession
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("invalidSession", "y");
            window.location.search = searchParams.toString();
            throw [
                error
            ];
        } else if (response.status === 403) {
            const error = {
                code: String(response.status),
                message: response.statusText || `Forbidden URL: ${response.url}`
            };
            // Trigger forbidden via forbiddenAction
            const searchParams = new URLSearchParams(window.location.search);
            searchParams.set("forbidden", error.message);
            window.location.search = searchParams.toString();
            throw [
                error
            ];
        } else {
            throw result; // throw result as error
        }
    };
}
/**
 * As the content may diff in responses, this will check header information to gain correct result.
 * Results might be JSON or TEXT.
 */ function getJsonResponse(response) {
    const contentType = response.headers.get("content-type");
    if (contentType && contentType.toLowerCase().indexOf("application/json") !== -1) {
        return response.json();
    } else {
        return response.text();
    }
}
function errorToHumanReadableMessage(error) {
    if (error.response) {
        // The server sent a response with an error status code
        return `Error: ${error.response.status} - ${error.response.statusText}`;
    } else if (error.request) {
        // The request was sent, but no response was received from the server
        return "The request could not be completed. No response from the server.";
    } else {
        // An error occurred while creating the request
        return "Error sending the request.";
    }
}
export { apiFetch, errorToHumanReadableMessage };
