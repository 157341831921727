import { makeAutoObservable } from "mobx";
/**
 * Example store for mobx usage.
 * Will count seconds.
 */ class TimerStore {
    // Our shared value
    secondsPassed = 0;
    /**
     * Stores also rootStore as reference to gain access ot other stores!
     */ rootStore;
    /**
     * Creates an instance of TimerStore.
     * @param {RootStore} rootStore
     */ constructor(rootStore){
        this.rootStore = rootStore;
        /**
         * Register mobx observables.
         * Will automatically make all properties observable.
         * To exclude see option below.
         */ makeAutoObservable(this, {
            rootStore: false
        });
        setInterval(()=>{
            this.increaseTimer();
        }, 1000);
    }
    increaseTimer() {
        this.secondsPassed += 1;
    }
}
export { TimerStore };
