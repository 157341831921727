import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next, Trans, useTranslation as reactUseTranslation } from "react-i18next";
function createLocalizationFactory() {
    i18n.use(Backend).use(LanguageDetector).use(initReactI18next).init({
        load: "languageOnly",
        fallbackLng: "en",
        supportedLngs: process.env.SUPPORTED_LANGS,
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: "/locale/{{lng}}/{{ns}}.json"
        },
        detection: {
            order: [
                "localStorage",
                "navigator"
            ],
            caches: [
                "localStorage"
            ],
            lookupLocalStorage: "language"
        }
    });
    const useTranslation = (ns, options)=>reactUseTranslation(ns, options);
    return {
        useTranslation
    };
}
export { createLocalizationFactory, Trans };
