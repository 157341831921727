import { ETechnicalDefinitionHostType } from "@devowl-wp/api-real-cookie-banner";
function useServiceTemplateTechnicalDefinitionHostKeys() {
    return Object.keys(ETechnicalDefinitionHostType).map((key)=>{
        if (isNaN(Number(key))) {
            return ETechnicalDefinitionHostType[key];
        }
        return undefined;
    }).filter(Boolean);
}
function useTranslateServiceTemplateTechnicalDefinitionHostKeySample(t, hostType) {
    switch(hostType){
        case ETechnicalDefinitionHostType.Main:
            return "devowl.io";
        case ETechnicalDefinitionHostType.MainPlusSubDomains:
            return ".devowl.io";
        case ETechnicalDefinitionHostType.Current:
            return "www.devowl.io";
        case ETechnicalDefinitionHostType.CurrentPlusProtocol:
            return "https://www.devowl.io";
        case ETechnicalDefinitionHostType.CurrentPlusSubDomains:
            return ".www.devowl.io";
        default:
            return t("not found");
    }
}
export { useServiceTemplateTechnicalDefinitionHostKeys, useTranslateServiceTemplateTechnicalDefinitionHostKeySample };
