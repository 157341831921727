function updateFilterFavoriteState(showOnlyFavourites, rcbUserSettingsStore) {
    if (rcbUserSettingsStore === null || rcbUserSettingsStore === void 0 ? void 0 : rcbUserSettingsStore.settings) {
        const { settings } = rcbUserSettingsStore;
        if (!settings.filter) {
            settings.filter = {
                showFavouritesOnly: showOnlyFavourites
            };
        } else {
            settings.filter.showFavouritesOnly = showOnlyFavourites;
        }
        rcbUserSettingsStore.updateSettings(settings);
    }
}
function updateSiderCollapseState(collapsed, rcbUserSettingsStore) {
    if (rcbUserSettingsStore === null || rcbUserSettingsStore === void 0 ? void 0 : rcbUserSettingsStore.settings) {
        const { settings } = rcbUserSettingsStore;
        if (!settings.sider) {
            settings.sider = {
                collapsed: collapsed
            };
        } else {
            settings.sider.collapsed = collapsed;
        }
        rcbUserSettingsStore.updateSettings(settings);
    }
}
export { updateFilterFavoriteState, updateSiderCollapseState };
