/**
 * Known form events.
 */ var EFormEvent;
(function(EFormEvent) {
    EFormEvent["TRANSLATION_FLAGS_CHANGED"] = "translation-flags-changed";
})(EFormEvent || (EFormEvent = {}));
/**
 * General event bus for all events.
 */ class EventBus {
    events = {};
    /**
     * Subscribe to an event.
     * @param eventName - The name of the event.
     * @param callback - The callback to be executed when the event is published.
     * @returns An unsubscribe function.
     */ subscribe(eventName, callback) {
        if (!this.events[eventName]) {
            this.events[eventName] = [];
        }
        this.events[eventName].push(callback);
        // Return an unsubscribe function
        return ()=>{
            this.events[eventName] = this.events[eventName].filter((cb)=>cb !== callback);
        };
    }
    /**
     * Publish an event.
     * @param eventName - The name of the event.
     * @param args - The arguments to be passed to the event callbacks.
     */ publish(eventName) {
        for(var _len = arguments.length, args = new Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++){
            args[_key - 1] = arguments[_key];
        }
        if (this.events[eventName]) {
            this.events[eventName].forEach((callback)=>callback(...args));
        }
    }
}
/**
 * Event bus for form events.
 */ const FormEventBus = new EventBus();
Object.freeze(FormEventBus);
export { FormEventBus, EFormEvent };
