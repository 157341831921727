var EDeviceType;
(function(EDeviceType) {
    EDeviceType["MOBILE"] = "mobile";
    EDeviceType["TABLET"] = "tablet";
    EDeviceType["DESKTOP"] = "desktop";
    EDeviceType["WIDESCREEN"] = "widescreen";
})(EDeviceType || (EDeviceType = {}));
class DeviceType {
    static isBigScreen(deviceType) {
        return [
            "desktop",
            "widescreen"
        ].includes(deviceType);
    }
}
export { EDeviceType, DeviceType };
