import { useState, useEffect } from "react";
import { EDeviceType } from "../types/device";
function useDeviceType() {
    const [deviceType, setDeviceType] = useState(null);
    useEffect(()=>{
        function handleResize() {
            const { matches } = window.matchMedia("(max-width: 767px), (max-width: 1199px) and (min-width: 768px), (min-width: 1200px)");
            if (matches) {
                const { innerWidth } = window;
                if (innerWidth <= 767) {
                    setDeviceType(EDeviceType.MOBILE);
                } else if (innerWidth <= 1199) {
                    setDeviceType(EDeviceType.TABLET);
                } else if (innerWidth <= 2000) {
                    setDeviceType(EDeviceType.DESKTOP);
                } else {
                    setDeviceType(EDeviceType.WIDESCREEN);
                }
            }
        }
        handleResize();
        window.addEventListener("resize", handleResize);
        return ()=>window.removeEventListener("resize", handleResize);
    }, []);
    return deviceType;
}
export { useDeviceType };
