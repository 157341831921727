var EServiceTemplatePurpose;
/**
 * list adjusted according to https://app.clickup.com/t/2de4a0k
 * https://app.clickup.com/2088/v/dc/218-357/218-8301, section 8, field purpose
 */ (function(EServiceTemplatePurpose) {
    EServiceTemplatePurpose["ServiceWebsiteFunction"] = "serviceWebsiteFunction";
    EServiceTemplatePurpose["PersonalDataProcessing"] = "personalDataProcessing";
    EServiceTemplatePurpose["PersonalDataUsage"] = "personalDataUsage";
    EServiceTemplatePurpose["PersonalDataAdditionalAccess"] = "personalDataAdditionalAccess";
    EServiceTemplatePurpose["PersonalDataLinking"] = "personalDataLinking";
    EServiceTemplatePurpose["LegalBasisLegitimateInterest"] = "legalBasisLegitimateInterest";
    EServiceTemplatePurpose["AutomatedDecisionAndProfilingImpact"] = "automatedDecisionAndProfilingImpact";
    EServiceTemplatePurpose["CookieStorageInformation"] = "cookieStorageInformation";
    EServiceTemplatePurpose["FingerprintingConsistingOf"] = "fingerprintingConsistingOf";
})(EServiceTemplatePurpose || (EServiceTemplatePurpose = {}));
export { EServiceTemplatePurpose };
