import { EServiceTemplatePurpose } from "@devowl-wp/api-real-cookie-banner";
/**
 * Retrieve an array of service template purpose keys.
 *
 * @returns An array of service template purpose keys.
 */ function useServiceTemplatePurposeKeys() {
    return Object.keys(EServiceTemplatePurpose).map((key)=>{
        if (isNaN(Number(key))) {
            return EServiceTemplatePurpose[key];
        }
        return undefined;
    }).filter(Boolean);
}
/**
 * Retrieve an array of service template purpose options with translated questions and default answers.
 *
 * @param t - The translation function to translate question keys.
 * @returns An array of service template purpose options.
 */ function useServiceTemplatePurposeOptions(t) {
    return Object.keys(EServiceTemplatePurpose).map((key)=>{
        if (isNaN(Number(key))) {
            const questionKey = EServiceTemplatePurpose[key];
            return {
                questionKey,
                question: useTranslatePurposeKey(t, questionKey),
                defaultAnswer: useTranslatePurposeKeyDefaultAnswer(t, questionKey)
            };
        }
        return undefined;
    }).filter(Boolean);
}
/**
 * Receive an array of IServiceTemplatePurposes, prefilled with available purposeKey of the enum including default answers, if available.
 */ function useServiceTemplatePurposesFromEnum(t) {
    return useServiceTemplatePurposeKeys().map((purposeKey, index)=>{
        return {
            question: purposeKey,
            orderPosition: index,
            isNewParagraph: false,
            answer: useTranslatePurposeKeyDefaultAnswer(t, purposeKey)
        };
    }).filter(Boolean);
}
/**
 * Translate given purpose / question key to a human readable and translated question.
 */ function useTranslatePurposeKey(t, purposeKey) {
    let defaultTranslation = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : t("Question not found!");
    switch(purposeKey){
        case EServiceTemplatePurpose.ServiceWebsiteFunction:
            return t("What functionality does the service provide on the website?");
        case EServiceTemplatePurpose.PersonalDataProcessing:
            return t("What personal data is processed?");
        case EServiceTemplatePurpose.PersonalDataUsage:
            return t("What happens to the personal data?");
        case EServiceTemplatePurpose.PersonalDataAdditionalAccess:
            return t("Who, beyond the designated provider, will have access to the personal data?");
        case EServiceTemplatePurpose.PersonalDataLinking:
            return t("Is personal data linked to other data (e.g. offline data, other Internet offerings, mergers across device boundaries)?");
        case EServiceTemplatePurpose.LegalBasisLegitimateInterest:
            return t("If legitimate interest is named as the legal basis, what is the specific legitimate interest?");
        case EServiceTemplatePurpose.AutomatedDecisionAndProfilingImpact:
            return t("Is data used for automated decision making including profiling? If yes, please describe the impact (e.g. IP-to-country lookup leads to regioned content).");
        case EServiceTemplatePurpose.CookieStorageInformation:
            return t("What information is stored / read in cookies?");
        case EServiceTemplatePurpose.FingerprintingConsistingOf:
            return t("Is fingerprinting used? If it is, what data does it consist of?");
        default:
            return defaultTranslation;
    }
}
/**
 * Translate given purpose / question key to a human readable and translated default answer or undefined
 */ function useTranslatePurposeKeyDefaultAnswer(t, purposeKey) {
    const interpolationOptions = {
        interpolation: {
            skipOnVariables: true
        }
    }; // keep variable names
    switch(purposeKey){
        case EServiceTemplatePurpose.ServiceWebsiteFunction:
            return t(`{{template.name}} enables the embedding of content published on {{template.name}} into websites to enhance the website.`, interpolationOptions);
        case EServiceTemplatePurpose.PersonalDataProcessing:
            return t(`This requires processing the user’s IP-address and metadata.`, interpolationOptions);
        case EServiceTemplatePurpose.CookieStorageInformation:
            return t(`Cookies or cookie-like technologies can be stored and read. These can contain personal data and technical data like user ID and consent.`, interpolationOptions);
        case EServiceTemplatePurpose.PersonalDataUsage:
            return t(`This data can be used to collect visited websites, detailed statistics about user behavior, and it can be used to improve the services of {{template.provider.name}}.`, interpolationOptions);
        case EServiceTemplatePurpose.AutomatedDecisionAndProfilingImpact:
            return t(`It can also be used for profiling, e.g. to provide you with personalized services, such as ads based on your interests or recommendations.`, interpolationOptions);
        case EServiceTemplatePurpose.PersonalDataAdditionalAccess:
            return t(`{{template.provider.name}} provides personal information to their affiliates and other trusted businesses or persons to process it for them, based on Google's instructions and in compliance with Google's privacy policy.`, interpolationOptions);
        case EServiceTemplatePurpose.PersonalDataLinking:
            return t(`This data may be linked by {{template.provider.name}} to the data of users logged in on the websites of {{template.provider.name}} (e.g. ).`, interpolationOptions);
        case EServiceTemplatePurpose.LegalBasisLegitimateInterest:
            return t(`The legitimate interest to use this service is to enable essential functionalities of the website.`, interpolationOptions);
        default:
            return undefined;
    }
}
export { useTranslatePurposeKey, useTranslatePurposeKeyDefaultAnswer, useServiceTemplatePurposeKeys, useServiceTemplatePurposesFromEnum, useServiceTemplatePurposeOptions };
