/**
 * Checks if localStorage is available. E.g. private modes in browsers
 * does not allow to write to localStorage.
 */ function localStorageTest() {
    const test = "devowl-admin-ui-site-test";
    try {
        localStorage.setItem(test, test);
        localStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}
export { localStorageTest };
