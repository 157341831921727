function getProviderPropertyTranslation(t, property, options) {
    switch(property){
        case "name":
            return t("Responsible person", options);
        case "streetAndNumber":
            return t("Street, Nr", options);
        case "placeAndPostcode":
            return t("Postcode, Place", options);
        case "email":
            return t("E-Mail", options);
        // contact section
        case "phone":
            return t("Phone", options);
        case "country":
            return t("Country", options);
        case "link":
            return t("Link", options);
    }
}
export { getProviderPropertyTranslation };
