const colors = {
    main: {
        background: "#f5f5f5"
    },
    component: {
        progress: {
            strokeColors: {
                "0%": "#fa541c",
                "100%": "#52c41a"
            }
        }
    },
    ci: {
        devowlBlue: "#1890ff",
        devowlBlueHover: "#1373CC"
    },
    translation: {
        incomplete: "orange",
        hasFlags: "#44878a",
        fuzzy: "#cda233",
        machineTranslated: "#ac2bca"
    }
};
export { colors };
