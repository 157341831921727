import { useEffect } from "react";
/**
 * Hook that alerts clicks outside of the passed ref
 * @author ChatGPT
 */ function useOutsideClick(ref, callback) {
    useEffect(()=>{
        // Function to call on click outside
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback();
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return ()=>{
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [
        ref,
        callback
    ]);
}
export { useOutsideClick };
