var EIso639One;
(function(EIso639One) {
    EIso639One["Abkhazian"] = "ab";
    EIso639One["Afar"] = "aa";
    EIso639One["Afrikaans"] = "af";
    EIso639One["Akan"] = "ak";
    EIso639One["Albanian"] = "sq";
    EIso639One["Amharic"] = "am";
    EIso639One["Arabic"] = "ar";
    EIso639One["Aragonese"] = "an";
    EIso639One["Armenian"] = "hy";
    EIso639One["Assamese"] = "as";
    EIso639One["Avaric"] = "av";
    EIso639One["Avestan"] = "ae";
    EIso639One["Aymara"] = "ay";
    EIso639One["Azerbaijani"] = "az";
    EIso639One["Bambara"] = "bm";
    EIso639One["Bashkir"] = "ba";
    EIso639One["Basque"] = "eu";
    EIso639One["Belarusian"] = "be";
    EIso639One["Bengali"] = "bn";
    EIso639One["Bislama"] = "bi";
    EIso639One["Bosnian"] = "bs";
    EIso639One["Breton"] = "br";
    EIso639One["Bulgarian"] = "bg";
    EIso639One["Burmese"] = "my";
    EIso639One["Catalan"] = "ca";
    EIso639One["Chamorro"] = "ch";
    EIso639One["Chechen"] = "ce";
    EIso639One["Chichewa"] = "ny";
    EIso639One["Chinese"] = "zh";
    EIso639One["ChurchSlavic"] = "cu";
    EIso639One["Chuvash"] = "cv";
    EIso639One["Cornish"] = "kw";
    EIso639One["Corsican"] = "co";
    EIso639One["Cree"] = "cr";
    EIso639One["Croatian"] = "hr";
    EIso639One["Czech"] = "cs";
    EIso639One["Danish"] = "da";
    EIso639One["Divehi"] = "dv";
    EIso639One["Dutch"] = "nl";
    EIso639One["Dzongkha"] = "dz";
    EIso639One["English"] = "en";
    EIso639One["Esperanto"] = "eo";
    EIso639One["Estonian"] = "et";
    EIso639One["Ewe"] = "ee";
    EIso639One["Faroese"] = "fo";
    EIso639One["Fijian"] = "fj";
    EIso639One["Finnish"] = "fi";
    EIso639One["French"] = "fr";
    EIso639One["WesternFrisian"] = "fy";
    EIso639One["Fulah"] = "ff";
    EIso639One["Gaelic"] = "gd";
    EIso639One["Galician"] = "gl";
    EIso639One["Ganda"] = "lg";
    EIso639One["Georgian"] = "ka";
    EIso639One["German"] = "de";
    EIso639One["Greek"] = "el";
    EIso639One["Kalaallisut"] = "kl";
    EIso639One["Guarani"] = "gn";
    EIso639One["Gujarati"] = "gu";
    EIso639One["Haitian"] = "ht";
    EIso639One["Hausa"] = "ha";
    EIso639One["Hebrew"] = "he";
    EIso639One["Herero"] = "hz";
    EIso639One["Hindi"] = "hi";
    EIso639One["HiriMotu"] = "ho";
    EIso639One["Hungarian"] = "hu";
    EIso639One["Icelandic"] = "is";
    EIso639One["Ido"] = "io";
    EIso639One["Igbo"] = "ig";
    EIso639One["Indonesian"] = "id";
    EIso639One["Interlingua"] = "ia";
    EIso639One["Interlingue"] = "ie";
    EIso639One["Inuktitut"] = "iu";
    EIso639One["Inupiaq"] = "ik";
    EIso639One["Irish"] = "ga";
    EIso639One["Italian"] = "it";
    EIso639One["Japanese"] = "ja";
    EIso639One["Javanese"] = "jv";
    EIso639One["Kannada"] = "kn";
    EIso639One["Kanuri"] = "kr";
    EIso639One["Kashmiri"] = "ks";
    EIso639One["Kazakh"] = "kk";
    EIso639One["CentralKhmer"] = "km";
    EIso639One["Kikuyu"] = "ki";
    EIso639One["Kinyarwanda"] = "rw";
    EIso639One["Kirghiz"] = "ky";
    EIso639One["Komi"] = "kv";
    EIso639One["Kongo"] = "kg";
    EIso639One["Korean"] = "ko";
    EIso639One["Kuanyama"] = "kj";
    EIso639One["Kurdish"] = "ku";
    EIso639One["Lao"] = "lo";
    EIso639One["Latin"] = "la";
    EIso639One["Latvian"] = "lv";
    EIso639One["Limburgan"] = "li";
    EIso639One["Lingala"] = "ln";
    EIso639One["Lithuanian"] = "lt";
    EIso639One["LubaKatanga"] = "lu";
    EIso639One["Luxembourgish"] = "lb";
    EIso639One["Macedonian"] = "mk";
    EIso639One["Malagasy"] = "mg";
    EIso639One["Malay"] = "ms";
    EIso639One["Malayalam"] = "ml";
    EIso639One["Maltese"] = "mt";
    EIso639One["Manx"] = "gv";
    EIso639One["Maori"] = "mi";
    EIso639One["Marathi"] = "mr";
    EIso639One["Marshallese"] = "mh";
    EIso639One["Mongolian"] = "mn";
    EIso639One["Nauru"] = "na";
    EIso639One["Navajo"] = "nv";
    EIso639One["NorthNdebele"] = "nd";
    EIso639One["SouthNdebele"] = "nr";
    EIso639One["Ndonga"] = "ng";
    EIso639One["Nepali"] = "ne";
    EIso639One["Norwegian"] = "no";
    EIso639One["NorwegianBokm\xe5l"] = "nb";
    EIso639One["NorwegianNynorsk"] = "nn";
    EIso639One["SichuanYi"] = "ii";
    EIso639One["Occitan"] = "oc";
    EIso639One["Ojibwa"] = "oj";
    EIso639One["Oriya"] = "or";
    EIso639One["Oromo"] = "om";
    EIso639One["Ossetian"] = "os";
    EIso639One["Pali"] = "pi";
    EIso639One["Pashto"] = "ps";
    EIso639One["Persian"] = "fa";
    EIso639One["Polish"] = "pl";
    EIso639One["Portuguese"] = "pt";
    EIso639One["Punjabi"] = "pa";
    EIso639One["Quechua"] = "qu";
    EIso639One["RomanianMoldavian"] = "ro";
    EIso639One["Romansh"] = "rm";
    EIso639One["Rundi"] = "rn";
    EIso639One["Russian"] = "ru";
    EIso639One["NorthernSami"] = "se";
    EIso639One["Samoan"] = "sm";
    EIso639One["Sango"] = "sg";
    EIso639One["Sanskrit"] = "sa";
    EIso639One["Sardinian"] = "sc";
    EIso639One["Serbian"] = "sr";
    EIso639One["Shona"] = "sn";
    EIso639One["Sindhi"] = "sd";
    EIso639One["Sinhala"] = "si";
    EIso639One["Slovak"] = "sk";
    EIso639One["Slovenian"] = "sl";
    EIso639One["Somali"] = "so";
    EIso639One["SouthernSotho"] = "st";
    EIso639One["Spanish"] = "es";
    EIso639One["Sundanese"] = "su";
    EIso639One["Swahili"] = "sw";
    EIso639One["Swati"] = "ss";
    EIso639One["Swedish"] = "sv";
    EIso639One["Tagalog"] = "tl";
    EIso639One["Tahitian"] = "ty";
    EIso639One["Tajik"] = "tg";
    EIso639One["Tamil"] = "ta";
    EIso639One["Tatar"] = "tt";
    EIso639One["Telugu"] = "te";
    EIso639One["Thai"] = "th";
    EIso639One["Tibetan"] = "bo";
    EIso639One["Tigrinya"] = "ti";
    EIso639One["TongaIslands"] = "to";
    EIso639One["Tsonga"] = "ts";
    EIso639One["Tswana"] = "tn";
    EIso639One["Turkish"] = "tr";
    EIso639One["Turkmen"] = "tk";
    EIso639One["Twi"] = "tw";
    EIso639One["Uighur"] = "ug";
    EIso639One["Ukrainian"] = "uk";
    EIso639One["Urdu"] = "ur";
    EIso639One["Uzbek"] = "uz";
    EIso639One["Venda"] = "ve";
    EIso639One["Vietnamese"] = "vi";
    EIso639One["Volap\xfck"] = "vo";
    EIso639One["Walloon"] = "wa";
    EIso639One["Welsh"] = "cy";
    EIso639One["Wolof"] = "wo";
    EIso639One["Xhosa"] = "xh";
    EIso639One["Yiddish"] = "yi";
    EIso639One["Yoruba"] = "yo";
    EIso639One["Zhuang"] = "za";
    EIso639One["Zulu"] = "zu";
})(EIso639One || (EIso639One = {}));
export { EIso639One };
