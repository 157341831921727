import { ETechnicalDefinitionDurationUnit } from "@devowl-wp/api-real-cookie-banner";
function useServiceTemplateTechnicalDefinitionDurationTypeKeys() {
    return Object.keys(ETechnicalDefinitionDurationUnit).map((key)=>{
        if (isNaN(Number(key))) {
            return ETechnicalDefinitionDurationUnit[key];
        }
        return undefined;
    }).filter(Boolean);
}
function useTranslateDurationTypeKey(t, typeKey) {
    switch(typeKey){
        case ETechnicalDefinitionDurationUnit.Seconds:
            return t("Second(s)");
        case ETechnicalDefinitionDurationUnit.Minutes:
            return t("Minute(s)");
        case ETechnicalDefinitionDurationUnit.Hours:
            return t("Hour(s)");
        case ETechnicalDefinitionDurationUnit.Days:
            return t("Day(s)");
        case ETechnicalDefinitionDurationUnit.Months:
            return t("Month(s)");
        case ETechnicalDefinitionDurationUnit.Years:
            return t("Year(s)");
        default:
            return t("Type not found!");
    }
}
export { useServiceTemplateTechnicalDefinitionDurationTypeKeys, useTranslateDurationTypeKey };
