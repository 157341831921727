const DEFAULT_PAGINATION_META_DATA = {
    defaultPageSize: 25,
    hideOnSinglePage: true,
    pageSizeOptions: [
        10,
        25,
        50
    ]
};
Object.freeze(DEFAULT_PAGINATION_META_DATA);
export { DEFAULT_PAGINATION_META_DATA };
