const spacing = {
    layout: {
        header: {
            height: 64
        },
        content: {
            paddingSmall: 10,
            paddingMedium: 15,
            padding: 25,
            marginSmall: 3,
            marginMedium: 10,
            margin: 25
        },
        footer: {
            height: 70
        },
        sider: {
            width: 220
        },
        image: {
            borderRadius: "1rem",
            padding: "1rem",
            margin: "1rem"
        }
    }
};
const templateFormDefaultProps = {
    labelCol: {
        flex: "0 0 220px"
    },
    wrapperCol: {
        flex: "1 800px 800px"
    },
    style: {
        width: "100% "
    },
    labelAlign: "right",
    colon: true,
    scrollToFirstError: true
};
const templateFormDefaultPropsSmall = {
    labelCol: {
        flex: "0 0 220px"
    },
    wrapperCol: {
        flex: "1 600px 600px"
    },
    style: {
        width: "100% "
    },
    labelAlign: "left",
    colon: true,
    scrollToFirstError: true
};
export { spacing, templateFormDefaultProps, templateFormDefaultPropsSmall };
