import { useInfiniteQuery } from "@tanstack/react-query";
import { getMedia } from "../api";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
function useMedia() {
    let isEnabled = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : true, searchTerm = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : "", cursorColumn = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : "id", cursorId = arguments.length > 3 && arguments[3] !== void 0 ? arguments[3] : undefined, limit = arguments.length > 4 && arguments[4] !== void 0 ? arguments[4] : 25;
    const { rcbAuthenticationStore } = useStores();
    const mediaQuery = useInfiniteQuery(QueryKeys.getMedia(cursorColumn, cursorId, limit, searchTerm), (param)=>{
        let { signal, pageParam } = param;
        var _rcbAuthenticationStore_session;
        const urlParameter = {
            cursor: pageParam || `${cursorColumn}:${cursorId ? cursorId : ""}`,
            limit
        };
        if (searchTerm && "" !== searchTerm.trim()) {
            urlParameter.search = searchTerm.trim();
        }
        return getMedia({
            params: urlParameter,
            signal,
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            }
        });
    }, {
        enabled: isEnabled,
        getPreviousPageParam: (currentPage)=>{
            var _currentPage_responseMetadata;
            return currentPage === null || currentPage === void 0 ? void 0 : (_currentPage_responseMetadata = currentPage.responseMetadata) === null || _currentPage_responseMetadata === void 0 ? void 0 : _currentPage_responseMetadata.cursor;
        },
        getNextPageParam: (lastPage)=>{
            var _lastPage_responseMetadata;
            return lastPage === null || lastPage === void 0 ? void 0 : (_lastPage_responseMetadata = lastPage.responseMetadata) === null || _lastPage_responseMetadata === void 0 ? void 0 : _lastPage_responseMetadata.nextCursor;
        },
        keepPreviousData: true
    });
    return mediaQuery;
}
export { useMedia };
