function extractVariableNamesFromScript(script) {
    const variableNames = [];
    if (script) {
        const pattern = /\{\{([A-Za-z_][A-Za-z0-9_äöüÄÖÜß]*)\}\}/g;
        let match;
        while((match = pattern.exec(script)) !== null){
            if (!variableNames.includes(match[1])) {
                variableNames.push(match[1]);
            }
        }
    }
    return variableNames;
}
export { extractVariableNamesFromScript };
