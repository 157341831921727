const QueryKeys = {
    // Session
    // CRUD
    createMagicLinkRequest: (email)=>[
            "session",
            "magicLinkRequest",
            email
        ],
    createMagicLinkSessionByToken: (magicLinkToken)=>[
            "session",
            "magicLinkSessionRequest",
            magicLinkToken
        ],
    createSessionByPasswordLogin: (email)=>[
            "session",
            "passwordLoginRequest",
            {
                email
            }
        ],
    // Media
    // CRUD
    media: ()=>[
            "media"
        ],
    getMedia: (cursorColumn, cursorId, limit, search)=>[
            "media",
            {
                cursorColumn,
                cursorId,
                limit,
                search
            }
        ],
    deleteMedia: (id)=>[
            "media",
            "delete",
            id
        ],
    // Pre-Release-Clients
    // CRUD
    getPreReleaseClients: (enabled, clientUuid)=>[
            "pre-release-clients",
            {
                enabled,
                clientUuid
            }
        ],
    // Release
    // CRUD
    getReleases: ()=>[
            "releases"
        ],
    getReleaseById: (id)=>[
            "release",
            id
        ],
    getReleaseDependencies: (customRef)=>[
            "release",
            "dependencies",
            customRef
        ],
    createRelease: (customRef)=>[
            "release",
            "post",
            customRef
        ],
    // Content-Blocker
    // CRUD
    getContentBlockerById: (id)=>[
            "content-blocker",
            "template",
            "id",
            id
        ],
    getContentBlockerByIdentifier: (identifier)=>[
            "content-blocker",
            "template",
            "identifier",
            identifier
        ],
    deleteContentBlocker: (id)=>[
            "content-blocker",
            "template",
            "delete",
            id
        ],
    createContentBlocker: (contentBlockerTemplate)=>[
            "content-blocker",
            "template",
            "create",
            {
                contentBlockerTemplate
            }
        ],
    editContentBlocker: (contentBlockerTemplate)=>[
            "content-blocker",
            "template",
            "edit",
            contentBlockerTemplate === null || contentBlockerTemplate === void 0 ? void 0 : contentBlockerTemplate.identifier,
            {
                contentBlockerTemplate
            }
        ],
    // Functions
    toggleFavouriteContentBlocker: (id)=>[
            "content-blocker",
            "template",
            "function",
            "toggleFavourite",
            id
        ],
    getContentBlockerTemplateLastEditors: (id)=>[
            "content-blocker",
            "template",
            "function",
            "last-editors",
            id
        ],
    // Metadata
    contentBlockerMeta: ()=>[
            "templates",
            "content-blockers",
            "meta"
        ],
    getContentBlockerMeta: (filterIdentifiers, includeDeleted)=>[
            "templates",
            "content-blockers",
            "meta",
            {
                filterIdentifiers,
                includeDeleted
            }
        ],
    // Statistics
    getContentBlockerStatistics: (favouritesOnly)=>[
            "content-blocker",
            "template",
            "statistics",
            favouritesOnly
        ],
    // Services
    // CRUD
    getServiceById: (id)=>[
            "service",
            "template",
            "id",
            id
        ],
    getServiceByIdentifier: (identifier)=>[
            "service",
            "template",
            "identifier",
            identifier
        ],
    deleteServiceTemplate: (id)=>[
            "service",
            "template",
            "delete",
            id
        ],
    createServiceTemplate: (serviceTemplate)=>[
            "service",
            "template",
            "create",
            {
                serviceTemplate
            }
        ],
    editServiceTemplate: (serviceTemplate)=>[
            "service",
            "template",
            "edit",
            serviceTemplate === null || serviceTemplate === void 0 ? void 0 : serviceTemplate.identifier,
            {
                serviceTemplate
            }
        ],
    // Functions
    toggleFavouriteService: (id)=>[
            "service",
            "template",
            "function",
            "toggleFavourite",
            id
        ],
    getServiceTemplateLastEditors: (id)=>[
            "service",
            "template",
            "function",
            "last-editors",
            id
        ],
    // Meta
    serviceMeta: ()=>[
            "templates",
            "services",
            "meta"
        ],
    getServiceMeta: (filterIdentifiers, includeDeleted)=>[
            "templates",
            "services",
            "meta",
            {
                filterIdentifiers,
                includeDeleted
            }
        ],
    // Statistics
    getServiceStatistics: (favouritesOnly)=>[
            "service",
            "template",
            "statistics",
            favouritesOnly
        ]
};
export { QueryKeys };
