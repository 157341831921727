/**
 * Get the CDN state for a service.
 *
 * @param isCdnEnabled - Whether the CDN is enabled on the service template.
 * @param isSpecialTreatmentScc - Whether the service has special treatment for standard contractual clauses.
 * @param isEmbeddingOnlyExternalResourcesEnabled - Whether the service has embedding only external resources enabled.
 * @returns The CDN state for the service.
 */ function getServiceCdnState(isCdnEnabled, isSpecialTreatmentScc, isEmbeddingOnlyExternalResourcesEnabled) {
    if (!isCdnEnabled) {
        return "disabled";
    }
    if (isSpecialTreatmentScc && isEmbeddingOnlyExternalResourcesEnabled) {
        return "enabledWithStandardClauses";
    }
    if (isSpecialTreatmentScc && !isEmbeddingOnlyExternalResourcesEnabled) {
        return "enabledWithStandardClausesAndNotEmbeddingOnlyExternalResources";
    }
    return "enabled";
}
/**
 * Check if the service has all fields visible, calculated by the CDN state.
 *
 * @param serviceCdnState - The CDN state for the service.
 * @returns Whether the service has all fields visible, calculated by the CDN state.
 */ function isServiceAllFieldsByCdnState(serviceCdnState) {
    const visibleStates = [
        "disabled",
        "enabledWithStandardClausesAndNotEmbeddingOnlyExternalResources"
    ];
    return visibleStates.includes(serviceCdnState);
}
export { getServiceCdnState, isServiceAllFieldsByCdnState };
