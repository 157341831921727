import { useQuery } from "@tanstack/react-query";
import { requestSessionByPasswordLogin } from "../api";
import { useStores } from "../store";
import { QueryKeys } from "../util/query-keys";
/**
 * Query a session via email and password login request.
 */ function useSessionByPasswordLoginRequest(email, password) {
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.createSessionByPasswordLogin(email), async (param)=>{
        let { signal } = param;
        await rcbAuthenticationStore.sessionLogout(); // invalidate old session - if possible
        return requestSessionByPasswordLogin(email, password, {
            signal,
            language: "en"
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: !!email && !!password,
        onSuccess: (sessionResponse)=>{
            rcbAuthenticationStore.writeSessionToLocalStorage(sessionResponse);
        }
    });
}
export { useSessionByPasswordLoginRequest };
