import { EExtendsMergeStrategy, EServiceTemplateDataProcessingInCountriesSpecialTreatment, EServiceTemplateGroup, EServiceTemplateLegalBasis, getServiceCdnState } from "@devowl-wp/api-real-cookie-banner";
import { Form } from "antd";
import { useEffect, useMemo } from "react";
import { FORM_FIELD_SERVICE_TEMPLATE_LEGAL_BASIS_NAME, FORM_FIELD_SERVICE_TEMPLATE_SERVICE_GROUP_NAME } from "../../components/route/real-cookie-banner/template";
/**
 * Hook to determine if the CDN field should be hidden based on the service template's CDN state and merge strategies.
 *
 * This method calculates the CDN state based on two main factors:
 * 1. Whether CDN is enabled (either directly or through inheritance)
 * 2. Whether special treatments for data processing are applied
 *
 * The possible states are:
 * - "disabled": CDN is not enabled
 * - "enabled": CDN is enabled without special treatments
 * - "enabledWithStandardClauses": CDN is enabled with special treatments
 *
 * @param fieldName - The name of the field to check for CDN state.
 * @returns The CDN field state.
 */ function useServiceCdnState(fieldName) {
    const form = Form.useFormInstance();
    const isCdn = Form.useWatch("isCdn", form);
    const isCdnMergeStrategy = Form.useWatch([
        "extendedMergeStrategies",
        "isCdn"
    ], form);
    const isEmbeddingOnlyExternalResources = Form.useWatch("isEmbeddingOnlyExternalResources", form);
    const isEmbeddingOnlyExternalResourcesMergeStrategy = Form.useWatch([
        "extendedMergeStrategies",
        "isEmbeddingOnlyExternalResources"
    ], form);
    const extended = Form.useWatch("extends", form);
    const specialTreatments = Form.useWatch("dataProcessingInCountriesSpecialTreatments", form);
    const isSpecialTreatmentEnabled = specialTreatments === null || specialTreatments === void 0 ? void 0 : specialTreatments.includes(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses);
    const specialTreatmentMergeStrategy = Form.useWatch([
        "extendedMergeStrategies",
        "dataProcessingInCountriesSpecialTreatments"
    ], form);
    const targetFieldMergeStrategy = Form.useWatch([
        "extendedMergeStrategies",
        fieldName
    ], form);
    const cdnFieldState = useMemo(()=>{
        var _extended_dataProcessingInCountriesSpecialTreatments;
        const isCdnEnabled = (extended === null || extended === void 0 ? void 0 : extended.isCdn) && isCdnMergeStrategy === EExtendsMergeStrategy.Keep || isCdn;
        const isEmbeddingOnlyExternalResourcesEnabled = (extended === null || extended === void 0 ? void 0 : extended.isEmbeddingOnlyExternalResources) && isEmbeddingOnlyExternalResourcesMergeStrategy === EExtendsMergeStrategy.Keep || (isEmbeddingOnlyExternalResources === null ? true : isEmbeddingOnlyExternalResources);
        const isSpecialTreatment = isSpecialTreatmentEnabled || (extended === null || extended === void 0 ? void 0 : (_extended_dataProcessingInCountriesSpecialTreatments = extended.dataProcessingInCountriesSpecialTreatments) === null || _extended_dataProcessingInCountriesSpecialTreatments === void 0 ? void 0 : _extended_dataProcessingInCountriesSpecialTreatments.includes(EServiceTemplateDataProcessingInCountriesSpecialTreatment.StandardContractualClauses)) && specialTreatmentMergeStrategy === EExtendsMergeStrategy.Keep;
        return getServiceCdnState(isCdnEnabled, isSpecialTreatment, isEmbeddingOnlyExternalResourcesEnabled);
    }, [
        isCdn,
        isCdnMergeStrategy,
        specialTreatments,
        isSpecialTreatmentEnabled,
        extended,
        specialTreatmentMergeStrategy,
        targetFieldMergeStrategy,
        isEmbeddingOnlyExternalResources,
        isEmbeddingOnlyExternalResourcesMergeStrategy
    ]);
    useEffect(()=>{
        if (cdnFieldState === "enabledWithStandardClauses") {
            form.setFieldValue(FORM_FIELD_SERVICE_TEMPLATE_SERVICE_GROUP_NAME, EServiceTemplateGroup.Essential);
            if (form.getFieldValue("extends")) {
                const mergeStrategies = form.getFieldValue("extendedMergeStrategies");
                mergeStrategies[fieldName] = EExtendsMergeStrategy.Overwrite;
                form.setFieldValue("extendedMergeStrategies", mergeStrategies);
            }
            form.setFieldValue([
                FORM_FIELD_SERVICE_TEMPLATE_LEGAL_BASIS_NAME
            ], EServiceTemplateLegalBasis.LegitimateInterest);
        }
    }, [
        cdnFieldState
    ]);
    return cdnFieldState;
}
export { useServiceCdnState };
