const DEFAULT_PAGINATION_TECHNICAL_DEFINITIONS = {
    defaultPageSize: 10,
    hideOnSinglePage: true,
    pageSizeOptions: [
        10,
        25,
        50,
        100
    ]
};
Object.freeze(DEFAULT_PAGINATION_TECHNICAL_DEFINITIONS);
export { DEFAULT_PAGINATION_TECHNICAL_DEFINITIONS };
