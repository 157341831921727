function mapValidationErrorToFormDataError(errors) {
    return errors.map((validationError)=>{
        if (validationError.fieldName) {
            return {
                name: validationError.fieldName,
                errors: [
                    validationError.message
                ]
            };
        }
        return undefined;
    }).filter(Boolean);
}
export { mapValidationErrorToFormDataError };
