import { useState } from "react";
function useOnOffToggle(initialValue) {
    const [value, setValue] = useState(initialValue);
    const toggleValue = ()=>{
        setValue(!value);
    };
    return [
        value,
        toggleValue
    ];
}
function useShouldVersionIncrease(initialValue) {
    const [shouldVersionIncrease, toggleVersionIncrease] = useOnOffToggle(initialValue);
    return [
        shouldVersionIncrease,
        toggleVersionIncrease
    ];
}
export { useShouldVersionIncrease };
