import { ERcbTier } from "@devowl-wp/api-real-cookie-banner";
function isSwitchableTier(extendedFrom, currentTier) {
    switch(extendedFrom){
        case ERcbTier.Free:
            return true; // any is allowed for current
        case ERcbTier.Pro:
            return extendedFrom === currentTier; // if base is pro, current must be pro
        default:
            return false;
    }
}
export { isSwitchableTier };
