import { EXPORT_REMOVABLE_CONTENT_BLOCKER, EXPORT_REMOVABLE_SERVICE } from "@devowl-wp/api-real-cookie-banner";
import { removePropertiesByName } from "../util";
/**
 * Trigger a download for given data, filename and fileType.
 *
 * @see inspired by and modified from: https://stackoverflow.com/questions/68811022/how-do-i-download-text-data-as-json-with-a-download-button-in-typescript-react
 */ function downloadFile(param) {
    let { data, fileName, fileType, fileExtension } = param;
    // Create a blob with the data we want to download as a file
    const blob = new Blob([
        data
    ], {
        type: fileType
    });
    // Create an anchor element and set its properties
    const a = document.createElement("a");
    a.download = `${fileName}-${Date.now()}.${fileExtension}`;
    a.href = window.URL.createObjectURL(blob);
    // Programmatically trigger the download
    a.click();
    // Clean up by revoking the URL and removing the anchor element
    window.URL.revokeObjectURL(a.href);
    a.remove();
}
/**
 * Prepare an exportable json string for ServiceTemplates.
 */ function prepareExportDataService(service) {
    const temp = JSON.parse(JSON.stringify(service));
    removePropertiesByName(temp, [
        ...EXPORT_REMOVABLE_SERVICE
    ]);
    return JSON.stringify(temp, null, "\t");
}
/*
 * Removes properties which are not allowed or known.
 */ function prepareImportDataService(service) {
    removePropertiesByName(service, [
        ...EXPORT_REMOVABLE_SERVICE
    ]);
    return service;
}
/**
 * Prepare an exportable json string for ServiceTemplates.
 */ function prepareExportDataContentBlocker(blocker) {
    const ruleGroupsWithId = structuredClone(blocker.ruleGroups); // handle special case with id (CU-86934facb)
    const temp = JSON.parse(JSON.stringify(blocker));
    removePropertiesByName(temp, [
        ...EXPORT_REMOVABLE_CONTENT_BLOCKER
    ]);
    temp.ruleGroups = ruleGroupsWithId;
    return JSON.stringify(temp, null, "\t");
}
/*
 * Removes properties which are not allowed or known.
 */ function prepareImportDataContentBlocker(blocker) {
    const ruleGroupsWithId = structuredClone(blocker.ruleGroups); // handle special case with id (CU-86934facb)
    removePropertiesByName(blocker, [
        ...EXPORT_REMOVABLE_CONTENT_BLOCKER
    ]);
    blocker.ruleGroups = ruleGroupsWithId;
    return blocker;
}
async function copyToClipboard(text) {
    try {
        await navigator.clipboard.writeText(text);
    } catch (err) {
        console.error("Unable to copy text: ", err);
    }
}
export { copyToClipboard, downloadFile, prepareExportDataContentBlocker, prepareExportDataService, prepareImportDataContentBlocker, prepareImportDataService };
