function getItem(settings) {
    const { label, key, icon, children, type, hidden, style } = settings;
    return {
        hidden,
        key,
        icon,
        children,
        label,
        type,
        style
    };
}
export { getItem };
