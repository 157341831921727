var ETranslationFlag;
/**
 * Translation flags for "tagging" translation string.
 * @see https://app.clickup.com/t/861n9jg7k (CU-861n9jg7k)
 */ (function(ETranslationFlag) {
    ETranslationFlag["Fuzzy"] = "fuzzy";
    ETranslationFlag["MachineTranslated"] = "machineTranslated";
})(ETranslationFlag || (ETranslationFlag = {}));
/**
 * Property name for the translations array.
 */ const TRANSLATIONS_PROPERTY_NAME = "translations";
/**
 * Property name for the translation info array.
 */ const TRANSLATION_INFO_PROPERTY_NAME = "translationInfo";
/**
 * Property name for the translation ids array.
 */ const TRANSLATION_IDS_PROPERTY_NAME = "translationIds";
/**
 * Suffix added to property names to indicate translation flags.
 *
 * @example
 * // For a property named 'title'
 * titleTranslationFlags: ETranslationFlag[];
 */ const TRANSLATION_FLAG_SUFFIX = "TranslationFlags";
export { ETranslationFlag, TRANSLATIONS_PROPERTY_NAME, TRANSLATION_IDS_PROPERTY_NAME, TRANSLATION_INFO_PROPERTY_NAME, TRANSLATION_FLAG_SUFFIX };
