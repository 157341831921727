import { useQuery } from "@tanstack/react-query";
import { useStores } from "../../store";
import { QueryKeys } from "../../util/query-keys";
import { getServiceTemplateLastEditors } from "../../api";
/**
 * Get last editors since release for given serviceTemplate via RCB Service API.
 */ function useGetServiceTemplateLastEditorsSinceRelease(id) {
    let enabled = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : true;
    var _String;
    const { rcbAuthenticationStore } = useStores();
    return useQuery(QueryKeys.getServiceTemplateLastEditors(id), (param)=>{
        let { signal } = param;
        var _rcbAuthenticationStore_session;
        return getServiceTemplateLastEditors({
            signal,
            language: "en",
            headers: {
                Authorization: `Bearer ${rcbAuthenticationStore === null || rcbAuthenticationStore === void 0 ? void 0 : (_rcbAuthenticationStore_session = rcbAuthenticationStore.session) === null || _rcbAuthenticationStore_session === void 0 ? void 0 : _rcbAuthenticationStore_session.jwt}`
            },
            params: {
                id: String(id)
            }
        });
    }, {
        staleTime: Infinity,
        retry: false,
        enabled: enabled && ((_String = String(id)) === null || _String === void 0 ? void 0 : _String.trim().length) > 0 && !isNaN(Number(id))
    });
}
export { useGetServiceTemplateLastEditorsSinceRelease };
