import { useEffect } from "react";
import { FormEventBus } from "../../util/eventBus";
/**
 * A custom hook that subscribes to a specific form event and executes a callback when the event is triggered.
 * @param eventName The name of the event to subscribe to.
 * @param callback The function to be called when the event is triggered.
 */ function useFormEvent(eventName, callback) {
    useEffect(()=>{
        const unsubscribe = FormEventBus.subscribe(eventName, callback);
        return ()=>{
            unsubscribe();
        };
    }, [
        eventName,
        callback
    ]);
}
export { useFormEvent };
