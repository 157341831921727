import { spacing } from ".";
const centered = {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    padding: spacing.layout.content.paddingMedium
};
export { centered };
