/**
 * Retrieves the value at the specified path from the given entity.
 *
 * @param entity - The object or array from which to retrieve the value.
 * @param path - An array representing the path of property names, array indexes, or symbols to follow.
 * @returns The value at the specified path, or undefined if the path is not found.
 *
 * @example
 * // Define an entity object
 * const entity = {
 *   user: {
 *     name: 'Alice',
 *     address: {
 *       city: 'Wonderland'
 *     }
 *   }
 * };
 *
 * // Define a path
 * const path = ['user', 'address', 'city'];
 *
 * // Retrieve the value at the specified path
 * const value = getValueByNamePath(entity, path);
 * console.log(value); // Output: 'Wonderland'
 *
 * @example
 * // Define another path
 * const path2 = ['user', 'age'];
 *
 * // Attempt to retrieve a non-existing value
 * const value2 = getValueByNamePath(entity, path2);
 * console.log(value2); // Output: undefined
 */ function getValueByNamePath(entity, path) {
    let current = entity;
    if (!!entity && !!path) {
        if (!Array.isArray(path)) {
            path = [
                path
            ];
        }
        for(let i = 0; i < path.length; i += 1){
            if (current === null || current === undefined) {
                return undefined;
            }
            current = current[path[i]];
        }
    }
    return current;
}
export { getValueByNamePath };
